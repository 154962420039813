const moment = require('moment');

export class Util {
  /**
   * Dado un dni o nie por parametro indica si esta bien formado
   * @param {*} value
   * @return Boolean
   */
  isValidDni(value) {
    if (value && value !== '') {
      var validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
      var nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
      var nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
      var passRexp = /^[a-z]{3}[0-9]{6}[a-z]?$/i;
      var str = value.toString().toUpperCase();

      var isNIF = nifRexp.test(str);
      var isNIE = nieRexp.test(str);
      var isPass = passRexp.test(str);

      if (!isNIF && !isNIE && !isPass) {
        return false;
      }

      if (!isPass) {
        var nie = str
          .replace(/^[X]/, '0')
          .replace(/^[Y]/, '1')
          .replace(/^[Z]/, '2');
        var letter = str.substr(-1);
        var charIndex = parseInt(nie.substr(0, 8)) % 23;
        if (validChars.charAt(charIndex) === letter) {
          return true;
        }
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
  /**
   * Dado un email, devuelve true si tiene el formato correcto
   * @param {*} value
   * @return Boolean
   */
  isValidEmail(value) {
    if (value && value !== '') {
      var emailRexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return emailRexp.test(value);
    } else {
      return true;
    }
  }

  /**
   * Dado un telefono, devuelve true si tiene el formato correcto
   * @param {*} value
   */
  isValidTelefono(value) {
    if (value && value !== '') {
      var telRexp = /^(\+)?[0-9]*$/;
      return telRexp.test(value);
    } else {
      return true;
    }
  }
  /**
   * Dado un string por parametro devuelve true si esta vacio o es null
   * @param {*} value
   * @return Boolean
   */
  isEmptyField(value) {
    if (!value || value === '') {
      return true;
    }
    return false;
  }
  isValidStringField(value, size) {
    if (value && value.length > size) {
      return false;
    }
    return true;
  }
  isValidFecha(value) {
    if (value) {
      var fecRexp = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;
      return fecRexp.test(value);
    } else {
      return true;
    }
  }
  mostrarEnConsola(metodo, mensaje, tipo) {
    var currentTime = moment().format('YYYY/MM/DD HH:mm:ss');
    console.log('[' + currentTime + '] ' + '[' + tipo + ']: ' + metodo + '-> ' + mensaje);
  }
  convertirMilisegundoAHoras(milisegundos, tipo) {
    switch (tipo) {
      case 1:
        return moment()
          .startOf('day')
          .millisecond(milisegundos)
          .format('H [h] mm [min]');
      case 2:
        return moment()
          .startOf('day')
          .millisecond(milisegundos)
          .format('H:mm');
      default:
        return moment()
          .startOf('day')
          .millisecond(milisegundos)
          .format('H:mm');
    }
  }
  convertirHorasAMilisegundos(horas) {
    return moment(horas).format('sss');
  }
  formatearNumero(value, decimales) {
    let val = (value / 1).toFixed(decimales).replace('.', ',');
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }
  formatMoney(number) {
    if (number !== undefined) {
      return number.toLocaleString('es-ES', { style: 'currency', currency: 'EUR' })
    } else {
      return ''
    }
  }
  sortBy = function() {
    var fields = [].slice.call(arguments),
      n_fields = fields.length;

    return function(A, B) {
      var a, b, field, key, reverse, result;
      for (var i = 0, l = n_fields; i < l; i++) {
        result = 0;
        field = fields[i];

        key = typeof field === 'string' ? field : field.name;

        a = A[key];
        b = B[key];

        if (typeof field.primer !== 'undefined') {
          a = field.primer(a);
          b = field.primer(b);
        }

        reverse = field.reverse ? -1 : 1;

        if (a < b) result = reverse * -1;
        if (a > b) result = reverse * 1;
        if (result !== 0) break;
      }
      return result;
    };
  };
  generarEan13(tallaInterna, talla, localizador) {
    let codAux
    let tallaAux
    // formamos los dos primeros digitos del ean13
    if (isNaN(talla) || talla.length > 2) {
      tallaAux = ('0' + tallaInterna).slice(-2) 
    } else {
      tallaAux = talla
    }
    //Los diez siguientes
    let locAux = ('0000000000' + localizador).slice(-10)
    
    codAux = tallaAux + locAux
    //Calculamos el dígito control
    let control
    let par=0
    let impar=0
    let first=1

    // Empezamos por el final
    for (var z = Array.from(codAux).length-1; z>=0; z--){
      if(first%2 == 0){
        par += parseInt(Array.from(codAux)[z])
      }else{
        impar += parseInt(Array.from(codAux[z]))*3
      }
      first++
    }
    control = (par+impar)%10
    if (control > 0) {
      control = 10 - control
    }
    codAux = tallaAux + locAux + control
    return codAux
  }
}
