import Moment from "moment";
import store from "../store/index";

export default {
  install(Vue) {
     Vue.mixin({
      created() {
      }
    });
     /*
    Función que devuelve si estamos en SYS4
    */
    Vue.prototype.isSys4 = function () {
      return process.env.VUE_APP_ISSYS4 == 'true'
    }
     /*
    Función que devuelve si estamos en SYS4 conjunto
    */
    Vue.prototype.isSys4Conjunto = function () {
      return process.env.VUE_APP_ISSYS4CONJUNTO == 'true'
    }
    /*
    Función que devuelve si los articulos tienen artbox
    */
    Vue.prototype.tieneArtbox = function () {
      return process.env.VUE_APP_ARTBOX == 'true'
    }
    /**
     * Función que te dice si se tactil el dispositivo
     */
    Vue.prototype.isTouchDevice = function () {
     if ("ontouchstart" in document.documentElement) {
       return true
     } else {
       return false
     }
    }
    /*
    Función que controla los permisos
    */
    Vue.prototype.permitido = function (nomPer) {
      let acl = store.getters.getAcl
      if (acl !== null && typeof acl.find((s) => s.permiso===nomPer) !== 'undefined' && acl.find((s) => s.permiso===nomPer).valor === 'S') {
        return true
      } else {
        return false
      }
    }
    // Vue.prototype.isInLikeLists = function (idArtic) {
    //   let likeList = this.$store.getters.getLikeLists
    //   if (likeList.length > 0 ) {
    //     console.log(likeList)
    //   }
    // }
    Vue.filter("shortDate", function (value) {
      return Moment(value).format('DD-MM-YYYY');
    })
    Vue.filter("chatDate", function (fecha1){
      if (fecha1 === '') {
        return ''
      } else if (Moment(fecha1).isSame(Moment(), 'day')) {
        return Moment(fecha1).format('HH:mm')
      } else if (Moment(fecha1).isSame(Moment().add(-1, 'days'))) {
        return 'Ayer '+ Moment(fecha1).format('HH:mm')
      } else {
        return Moment(fecha1).format('DD-MM-YYYY HH:mm');
      }
      
    })
  }
}