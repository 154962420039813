<template>
  <v-card>
    <v-img
      v-bind:src="fotoDefecto"
      class="white--text align-end pb-2"
      gradient="to bottom, rgba(0,0,0,.0),  rgba(0,0,0,.0), rgba(0, 0, 0, 0.15)"
      height="170px"
      contain
    > </v-img>
      <v-card-title class="body-1 text-uppercase pb-0 font-weight-bold primary--text pt-3" v-text="product.modelo">
      </v-card-title>
      <v-card-subtitle class="d-flex justify-space-between py-2 align-center">
       <span class="font-weight-light  pa-0" v-text="product.localizador"></span>
       <p class="text-uppercase d-flex accent--text ma-0 text-body-1 mt-1">{{precio}} €</p>
      <!-- <p class="ma-0" v-text="Colores"></p> -->
    </v-card-subtitle>
  </v-card>
</template>
<script lang="ts">
  import Vue from 'vue'
  // import { TiendaApiService } from '../api/TiendaApiService'

  // const tiendaApiService = new TiendaApiService()

  export default Vue.extend({
    name: 'ProductoLista',
    props: ['product','opId'],
    data () {
      return {
        precio: '',
        fotoDefecto: ''
      }
    },
    mounted () {
    this.init()
    
  },
  methods: {
    init() {
      this.getFotos()
      // this.getPrecios()
      this.getPrecio()
    },
    // getPrecios(){
    //   tiendaApiService.getPreciosByIdArtandIdOp(this.product.id,this.opId)
    //     .then(data => {
    //       this.precios = data 
    //       this.getPrecio()
    //     })
    //     .catch(error => {
    //       console.log('tiendaApiService - getPreciosByIdArtandIdOp ' + error)
    //     })

    // },
    getPrecio() {
      // si queremos sacar el precio de la tienda
      outer_loop:
      for (var i = 0; i < this.product.tallas.length; i++) {
        for (var y = 0; y < this.product.tallas[i].stocks.length; y++) {
          if (this.product.tallas[i].stocks[y].precio) {
            this.precio=this.product.tallas[i].stocks[y].precio.precio
            if (this.product.tallas[i].stocks[y].id.id_op == this.opId) {
              break outer_loop
            } 
          }
        }
      }
    },
    getFotos() {
      if (this.product.fotos) {
        this.product.fotos.forEach(element =>{
          if (element.porDefecto === "S") {
            if (element.url && !this.isSys4Conjunto()) {
              this.fotoDefecto = element.url
            } else {
              this.fotoDefecto = ("data:image/png;base64," + element.imagenBytes)
            }
          } else {
            if (element.url && !this.isSys4Conjunto()) {
              if (this.fotoDefecto === '') {
                this.fotoDefecto = this.fotoSecundaria
              }
            } else {
              if (this.fotoDefecto === '') {
                this.fotoDefecto = ("data:image/png;base64," + element.imagenBytes)
              } 
            }
          }
        })
      } 
    },
  }
})
</script>