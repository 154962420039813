<template>
  <v-row class="d-flex elevation-0 ">
    <div elevation="0" class="pl-0 header-icon">
        <v-icon class="icon datos"></v-icon>
    </div>
  
 
  </v-row>
  <!--
  
    <v-btn class="icon ticket">
    </v-btn>
</v-card>-->
</template>


<script lang="ts">
  import Vue from 'vue'

  export default Vue.extend({
    name: 'Header',
 
  })
</script>
