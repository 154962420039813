<template>
  <div>
    <div v-if="cargando" class="cargando text-center mt-15">
      <v-progress-circular indeterminate color="#2562a4"></v-progress-circular>
    </div>
    <v-card v-else flat class="d-flex flex-column">
      <v-expansion-panels accordion class="elevation-0 search-expansion">
        <v-expansion-panel class="transparent elevation-0 pa-0">
          <!-- <v-expansion-panel-header class=" "><v-icon class="icon search2"></v-icon></v-expansion-panel-header> -->
          <v-expansion-panel-content class="px-0">
            <v-row class="col-12 pa-0 ma-0 scan">
              <v-col class="primary col-12 pa-0">
                <Scan />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-expansion-panels class="ticket-expansion">
        <v-expansion-panel v-for="ticket in tickets" :key="ticket.cabecera.msct_uid" :class="[opUid === ticket.cabecera.msct_opuid ? '' : 'op']" >
          <v-expansion-panel-header class="py-0">
            <v-col>
              <p class="text-uppercase mb-1 primary--text font-weight-medium">{{ ticket.cabecera.msct_id_cliente }}</p>
              <v-list-item-subtitle class="text-uppercase accent--text mb-3">{{ new Date(ticket.cabecera.msct_fecha).toLocaleDateString('es-ES', { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' })}} </v-list-item-subtitle>
              <p class="mb-1 "><span class="caption mr-2">Tipo</span>
                <span class="font-weight-medium" v-if="ticket.cabecera.msct_tipo === 0">TICKET APARCADO</span>
                <span class="font-weight-medium" v-else>RESERVA</span>
              </p>
              <!-- <p class="mb-1"><span class="caption mr-2">ID</span><span class="font-weight-medium">16289563-C</span></p> -->
            </v-col>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-divider></v-divider>
            <v-list class="pa-0 ticket-list">
              <v-list-item-group>
                <v-list-item v-for="linea in ticket.lineasticket" :key="linea.id">
                  <LineaTicket :lineaticket="linea" :ref="linea.mslt_uid"></LineaTicket>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <v-divider></v-divider>
            <v-row class="py-2">
              <v-col>
                <p class="mb-0 caption">Base imponible</p>
                <p class="mb-1 primary--text font-weight-bold">36,50€</p>
              </v-col>
              <v-col class="justify-end">
                <p class="mb-0 caption">Iva</p>
                <p class="mb-1 primary--text font-weight-bold">36,50€</p>
              </v-col>
              <v-col>
                <p class="mb-0 caption">Total</p>
                <p class="mb-1 primary--text font-weight-bold">60,50€</p>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="d-flex pt-0 ma-0 px-3 d-flex align-center">
              <v-col cols="2">
                <v-btn v-if="($store.getters.getOpuid == ticket.cabecera.msct_opuid) && ticket.cabecera.msct_tipo === 0"
                 icon small @click="qDeleteTicket(ticket.cabecera.msct_uid)"><v-icon>mdi-delete</v-icon> </v-btn>
              </v-col>
              <v-col cols="2">
                <v-btn v-if="ticket.cabecera.msct_tipo === 0"
                 icon small @click="desaparcarTicket(ticket)"><v-icon>mdi-cart-arrow-down</v-icon> </v-btn>
              </v-col>
              <v-col cols="8" class="primary--text  d-flex flex-column align-end">
                <p class="mb-0">Total impuestos incluidos</p>
                <p class="text-h4 mb-4 font-weight-medium text-">36,50€</p>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-divider></v-divider>
      <v-divider></v-divider>
    </v-card>
    <v-snackbar v-model="snackbar" :multi-line="multiLine" :color="snackColor">
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn fab text v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import LineaTicket from './LineaTicket';
import { TicketApiService } from '@/api/TicketApiService';
import { Util } from '@/api/Util';

const ticketApiService = new TicketApiService();
const util = new Util();

export default {
  name: 'listaTickets',
  components: { LineaTicket },

  data() {
    return {
      id: '',
      dialog: true,
      prodAux: '',
      opUid: this.$store.getters.getOpuid,
      noTicketLabel: 'No hay tickets aparcados',
      diasReserva: 0,
      diasReservaExtra: 0,
      cargando: false,
      cartlist: [],
      snackbar: false,
      multiLine: true,
      snackColor: '',
      snackText: '',
    };
  },
  computed: {
    tickets() {
      return this.$store.state.tienda.ticketsAparcados;
    },
    cambiotienda() {
      return this.$store.getters.getOpuid;
    },
    hasTicketSearched() {
      return this.$store.state.tienda.hasTicketSearched;
    },
  },
  watch: {
    hasTicketSearched() {
      this.init();
    },
    cambiotienda(value) {
      this.opUid = value;
      this.init();
    },
  },
  mounted() {
    this.$root.$on('actualizarTickets', () => {
        this.init()
    });
    this.init();
  },
  methods: {
    init() {
      this.getDiasReserva();
      this.$store.state.ticketsAparcados = [];
      if (this.$store.state.tienda.hasTicketSearched !== 0) {
        this.getTicketBy();
      } else {
        this.getAllTicketAparOrderByOpuid();
      }
    },
    getDiasReserva() {
      ticketApiService
        .getDiasReserva()
        .then((data) => {
          // console.log(data)
          this.diasReserva = data.list.ticket_aparcado_dias_reserva;
          this.diasReservaExtra = data.list.ticket_aparcado_dias_reserva_extra;
        })
        .catch((error) => {
          if (error.response.status !== 404) {
            //alert ("Ha ocurrido un error")
            util.mostrarEnConsola('ticketApiService - getDiasReserva', error, 'ERROR');
          }
        });
    },
    getAllTicketAparOrderByOpuid() {
      this.cargando = true;
      ticketApiService
        .getAllTicketAparOrderByOpuid(this.opUid)
        .then((data) => {
          // console.log(data)
          this.$store.state.tienda.ticketsAparcados = data.list;
          this.cargando = false;
        })
        .catch((error) => {
          this.cargando = false;
          this.$store.state.tienda.ticketsAparcados = [];
          if (error.response.status !== 204) {
            // alert('Ha ocurrido un error');
            util.mostrarEnConsola('ticketApiService - getAllTicketAparOrderByOpuid', error, 'ERROR');
          }
        });
    },
    qDeleteTicket(cabuid) {
      if (confirm('¿Deseas borrar el ticket?')) {
        this.borrarTicket(cabuid);
      }
    },
    borrarTicket(cabuid) {
      let cabAux = {
        msct_uid: cabuid,
      };
      ticketApiService
        .deleteTicket(cabAux)
        .then(() => {
          this.$store.commit('removeFromTicketsAparcados', cabuid);
          this.snackbar = true
          this.snackColor = 'green'
          this.snackText = 'Ticket borrado con éxito'
        })
        .catch((error) => {
          this.snackbar = true
          this.snackColor = 'red darken-2'
          this.snackText = 'Ha ocurrido un error'
          util.mostrarEnConsola('ticketApiService - deleteTicket', error, 'ERROR');
        });
    },
    desaparcarTicket(ticket) {
      if (confirm('¿Deseas desaparcar el ticket?')) {
        console.log(ticket);
        //let arrayProductos = []
        let borrarTicket = true;
        let correcto = true;
        ticket.lineasticket.forEach((linea) => {
          correcto = this.$refs[linea.mslt_uid][0].addToCart();
          if (!correcto) {
            borrarTicket = false;
          }
        });
        if (borrarTicket) {
          localStorage.setItem('tipoTicket', ticket.cabecera.msct_tipo);
          localStorage.setItem('idClienteTicket', ticket.cabecera.msct_id_cliente);
          if (ticket.cabecera.msct_tipo === 1) {
            localStorage.setItem('fechaTicket', ticket.cabecera.msct_fecha);
          }
          this.borrarTicket(ticket.cabecera.msct_uid);
          this.snackbar = true
          this.snackColor = 'green'
          this.snackText = 'El ticket esta en el carrito'
        } else {
          this.snackbar = true
          this.snackColor = 'red darken-2'
          this.snackText = 'Ha ocurrido un error'
        }
      }
    },
    getTicketBy() {
      var filtro = {
        opuid: this.opUid,
      };
      if (this.$store.state.tienda.ticketVendedorSearched !== '') {
        let vendedor = this.$store.state.tienda.ticketVendedorSearched;
        Object.assign(filtro, { vendedor: vendedor });
      }
      if (this.$store.state.tienda.ticketClienteSearched !== '') {
        let cliente = this.$store.state.tienda.ticketClienteSearched;
        Object.assign(filtro, { cliente: cliente });
      }
      if (this.$store.state.tienda.ticketFechaDesdeSearched !== '') {
        let fechaDesde = this.$store.state.tienda.ticketFechaDesdeSearched;
        Object.assign(filtro, { fechaDesde: fechaDesde });
      }
      if (this.$store.state.tienda.ticketFechaHastaSearched !== '') {
        let fechaHasta = this.$store.state.tienda.ticketFechaHastaSearched;
        Object.assign(filtro, { fechaHasta: fechaHasta });
      }
      if (this.$store.state.tienda.ticketTipoSearched !== '') {
        let tipo = this.$store.state.tienda.ticketTipoSearched;
        Object.assign(filtro, { tipo: tipo.id });
      }
      // this.cargando = true
      ticketApiService
        .findTicket(filtro)
        .then((data) => {
          // this.cargando = false
          if (data) {
            this.$store.state.tienda.ticketsAparcados = data.data.list;
          } else {
            this.$store.state.tienda.ticketsAparcados = [];
            util.mostrarEnConsola('ticketApiService - getTicketBy', data.length, 'No hay tickets');
          }
          util.mostrarEnConsola('ticketApiService - getTicketBy', data.length, 'OK');
        })
        .catch((error) => {
          // this.cargando = false
          this.$store.state.tienda.ticketsAparcados = [];
          if (error.response.status !== 404) {
            // alert('Ha ocurrido un error');
            util.mostrarEnConsola('ticketApiService - getTicketBy', error, 'ERROR');
          }
        });
    },
    semaforoReserva(fecha) {
      var fechaAux = new Date(fecha);
      var hoy = new Date();
      fechaAux.setDate(fechaAux.getDate() + parseInt(this.diasReserva));
      if (fechaAux > hoy) {
        return 'green';
      } else {
        fechaAux.setDate(fechaAux.getDate() + parseInt(this.diasReservaExtra));
        if (fechaAux > hoy) {
          return 'orange';
        } else {
          return 'red';
        }
      }
    },
  },
};
</script>
<style scoped>
.op {
  background-color: #ffffe6 !important; 
}
</style>