<template>
  <v-main class="background time-control d-flex">  
    <v-app-bar  elevation="0" class="pl-16" color="transparent">
          <Header/>
    </v-app-bar>
      <Menu />
    <v-card class="d-flex justify-center flex-column pt-10"
      color="white"
      elevation="0">
        <v-card-title class="justify-center primary--text py-2">{{ fechaActualEsp }}</v-card-title>
        <v-card-title class="text-h2 primary--text font-weight-bold py-0 text-center justify-center">{{ horaActual }}h</v-card-title>
        <v-card-title class="justify-center primary--text py-2">{{ horario }}</v-card-title>
    </v-card>
    <v-list color="transparent" class="pb-0">
      <v-card elevation="0">
        <h3 class="text-uppercase primary--text text-center py-3">
          <v-icon class="icon lista ">
          </v-icon>
          Historial de fichaje
        </h3>
        <v-divider></v-divider>
      </v-card>
      <v-card class="scroll" color="transparent" elevation="0">
        <v-list-item  
          v-for="fichajeItem in fichajeListReversed"
          :key="fichajeItem.msfichajeid"
          class="pl-8">
          <v-list-item-avatar
              color="secondary"
              size="30">
              <v-icon
              class="icon"
              :class="fichajeItem.msfichajetipo === 1 ? 'play' : 'stop'">
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-text="fichajeItem.horaformatted" class="primary--text font-weight-bold text-h6"></v-list-item-title>
            <v-list-item-subtitle v-text="fichajeItem.msfichajeobs"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-list> 
    <v-avatar v-if="duracionActual !== ''"
      class="primary--text time d-flex flex-column"
      size="95"
      ><h4 class="text-h6 ">{{ duracionActual }}h</h4>
      <span class="text-body-2">Trabajadas</span>
    </v-avatar>
    <v-btn class="icon play"
      v-if="estadoFichaje === 0 || estadoFichaje === 3 || estadoFichaje === 2"
      fab
      @click="guardar('E')"
      color="secondary"
      elevation="1"
      right
      bottom
      large
      absolute
    ></v-btn>
    <v-btn class="icon stop"
      v-else
      fab
      color="secondary"
      elevation="1"
      bottom
      right
      large
      absolute
      @click="overlay = !overlay;"
    ></v-btn>
    <v-overlay
      :absolute="absolute"
      :value="overlay"
      light
      class="theme--light main-overlay"
    > 
    <v-btn
      @click="overlay = false"
        right
        absolute
        fab
        top
        small
        color="accent"
        elevation="0"
    > <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-card class="theme--light col-12 pt-10 pb-4 rounded-0">
      <v-row class="px-4 flex-nowrap">
          <v-select
            :items="items"
            label="Motivo de salida"
            class="mr-4 "
            color="accent"
            v-model="motivo"
          ></v-select>
          <v-btn 
            fab
            color="secondary"
            elevation="1"
            large
            @click="guardar('S')"
          ><v-icon>mdi-send</v-icon></v-btn>
      </v-row>
    </v-card>
    </v-overlay>
  </v-main>   
</template>

<script lang="ts">
  import Vue from 'vue'
  import Menu from '../components/Menu.vue'
  import Header from '../components/TimeControl/THeader.vue'
  import { FichajeApiService } from '../api/FichajeApiService'
  import { JornadaApiService } from '../api/JornadaApiService'
  import { HorariodiaApiService } from '../api/HorariodiaApiService'
  import { ChatApiService } from '../api/ChatApiService'

  const chatApiService = new ChatApiService()
  const fichajeApiService = new FichajeApiService()
  const jornadaApiService = new JornadaApiService()
  const horariodiaApiService =  new HorariodiaApiService()
  const moment = require("moment") 

  export default Vue.extend({
    name: 'TimeControl',
    components: {
      Menu,
      Header,
    },
     data: () => ({
      items: ['Pausa para comer', 'Otros', 'Fin de jornada'],
      motivo: 'Otros',
      ahora: moment(),
      fichajeList: [],
      mode: '',
      lang: '',
      estadoFichaje: 0,
      estado: {
        inicial: 0,
        entrada: 1,
        salida: 2,
        pausa: 3
      },
      fichajeNuevo: {
        select: -1,
        tipo: 0,
        tipoStr: "",
        observaciones: ""
      },
      absolute: true,
      overlay: false,
      personaJornada: [],
      horariodia: [],
      horario: '',
      duracionActual: '',
      duracion: ''
    }),
    computed: {
      horaActual: function() {
        return moment(this.ahora).format("H:mm")
      },
      fechaActual: function() {
        return moment(this.ahora).format("YYYY-MM-DD");
      },
      fechaActualEsp: function() {
        return moment(this.ahora).format("DD/MM/YYYY");
      },
      fichajeListReversed: function() {
        let fichajeListReversed = this.fichajeList
        return fichajeListReversed.reverse()
      },
    },
    watch: {
      fichajeList: {
        handler(n, o) {
          if (n !== o) {
            this.calcularEstado()
            this.checkDia()
          }
        },
        deep: true
      }
    },
    created () {
      this.intervalDuracionActual = setInterval(() => {
        this.ahora = moment()
        this.calcularDuracionActual()
      }, 1000)
      if (!this.intervalMinutosTrabajados) {
        this.intervalMinutosTrabajados = setInterval(() => {
          this.calcularMinutosTrabajados()
        }, 1000)
      }
      this.init()
    },
    methods: {
      init() {
        this.lang = this.$store.getters.lang
        moment.locale(this.lang)
        this.findAllFichajeFilter([
          {
            filterFiltro: true,
            filterTipoFiltro: "EQUALS",
            filterCampo: "MSF_personauid",
            filterValor: this.$store.state.userInfo.datos.mspersonauid
          },
          {
            filterFiltro: true,
            filterTipoFiltro: "EQUALS",
            filterCampo: "MSF_fecha",
            filterValor: moment(this.fechaActual).format("YYYY-MM-DD")
          },
          { filterOrderBy: true, filterTipoOrderBy: "ASC", filterCampo: "MSF_fecha" },
          { filterOrderBy: true, filterTipoOrderBy: "ASC", filterCampo: "MSF_hora" }
        ])
        this.getPersonajornadaByFechaAndPersonaUid()
      },
      calcularDuracionActual() {
        if (this.fichajeList.length>0) {
          if (this.estadoFichaje === 1) {
            this.duracionActual = this.convertirMilisegundoAHoras(this.duracion + Math.abs(moment.duration(moment(this.fichajeList[0].msfichajehora, 'hh:mm').diff(moment(this.ahora, 'hh:mm')))))
          }
        }
      },
      calcularMinutosTrabajados() {
        this.$store.state.userInfo.minutosTrabajados = this.convertirHorasAMinutos(this.duracionActual)
      },
      findAllFichajeFilter(fichajeFilter) {
        fichajeApiService
          .findAllFichajeFilter(fichajeFilter)
          .then(data => {
            if (data) {
              this.fichajeList = data
              // Formateamos las horas y sumamos el total
              let horaAnterior = 0
              let duracion = 0
              let inicial = true
              this.fichajeList.forEach(fichajeListItem => {
                fichajeListItem.horaformatted = moment(fichajeListItem.msfichajehora, "H:mm").format("H:mm")
                if (inicial) {
                  horaAnterior = fichajeListItem.msfichajehora
                  inicial = false
                } else {
                  if (fichajeListItem.msfichajetipo === 1) {
                    // Fichaje tipo entrada
                    horaAnterior = fichajeListItem.msfichajehora
                  } else {
                    // Fichaje tipo salida
                    if (horaAnterior != 0) {
                      const duracionParcial = moment.duration(moment(fichajeListItem.msfichajehora, "hh:mm").diff(moment(horaAnterior, "hh:mm")))
                      duracion += duracionParcial
                      fichajeListItem.duracionParcial = this.convertirMilisegundoAHoras(duracionParcial)
                      horaAnterior = 0
                    }
                  }
                }
              })
              this.totalHoras = this.convertirMilisegundoAHoras(duracion, 1)
            } else {
              console.log("Realizarfichaje - findAllFichajeFilter No se han encontrado registros")
            }
          })
          .catch(error => {
            console.log("Realizarfichaje - findAllFichajeFilter" + error)
          })
      },
      guardar(mode) {
        if (mode) {
          this.mode = mode
        }
        switch (this.mode) {
          case "E": // Entrada
            // cambiamos el dia de trabajo si sees un dia nuevo
            if (moment().diff(this.$store.state.userInfo.diaTrabajo, 'days') != '0') {
              this.$store.state.userInfo.extender10Min =  false
              this.$store.state.userInfo.extenderIlimitado = false
              this.$store.state.userInfo.diaTrabajo = moment().startOf('day')
            }
            this.fichajeNuevo.tipo = 1
            this.fichajeNuevo.tipoStr = "E"
            this.fichajeNuevo.observaciones = "Entrada"
            this.$store.state.userInfo.trabajando = true
            // this.interval3 = setInterval(() => this.updateUserLastActivity(), 1000)
            if (!this.intervalMinutosTrabajados) {
              this.intervalMinutosTrabajados = setInterval(() => {
                this.calcularMinutosTrabajados()
              }, 1000)
            }
            break
          case "P": // Pausa
            if (this.estadoFichaje === this.estado.pausa) {
              // Vuelta de la pausa
              this.fichajeNuevo.tipo = 1
              this.fichajeNuevo.tipoStr = "E"
              this.fichajeNuevo.observaciones = "Entrada"
              this.$store.state.userInfo.trabajando = true
              // this.interval3 = setInterval(() => this.updateUserLastActivity(), 1000)
            } else {
              // Inicio de la pausa
              clearInterval(this.interval3)
              clearInterval(this.intervalMinutosTrabajados)
              this.fichajeNuevo.tipo = 3
              this.fichajeNuevo.tipoStr = "P"
              this.$store.state.userInfo.trabajando = false
              if (this.fichajeNuevo.select.tipo === undefined) {
                this.fichajeNuevo.observaciones = "Pausa"
              } else if (this.fichajeNuevo.select.tipo !== 0) {
                this.fichajeNuevo.observaciones = this.fichajeNuevo.select.tipoStr
              } else if (this.fichajeNuevo.select.tipo === 0 && this.fichajeNuevo.observaciones === "") {
                this.fichajeNuevo.observaciones = "Otros"
              }
            }
            break
          case "S": // Salida
            clearInterval(this.interval3)
            clearInterval(this.intervalMinutosTrabajados)
            this.fichajeNuevo.tipo = 2
            this.fichajeNuevo.tipoStr = "S"
            this.fichajeNuevo.observaciones = this.motivo
            this.overlay = false
            this.$store.state.userInfo.trabajando = false
            break
          default:
            break
        }
        let fichaje = {
          msfichajefecha: moment(this.fechaActual, "YYYY-MM-DD"),
          msfichajepersonauid: this.$store.state.userInfo.datos.mspersonauid,
          msfichajetipo: this.fichajeNuevo.tipo,
          msfichajetipostr: this.fichajeNuevo.tipoStr,
          msfichajehora: moment(this.horaActual, "H:mm"),
          msfichajeobs: this.fichajeNuevo.observaciones
        }
        this.createFichaje(fichaje)
        this.fichajeModal = false
      },
      createFichaje(fichaje) {
        fichajeApiService
          .createFichaje(fichaje)
          .then(() => {
            this.findAllFichajeFilter([
              {
                filterFiltro: true,
                filterTipoFiltro: "EQUALS",
                filterCampo: "MSF_personauid",
                filterValor: this.$store.state.userInfo.datos.mspersonauid
              },
              {
                filterFiltro: true,
                filterTipoFiltro: "EQUALS",
                filterCampo: "MSF_fecha",
                filterValor: moment(this.fechaActual).format("YYYY-MM-DD")
              },
              { filterOrderBy: true, filterTipoOrderBy: "ASC", filterCampo: "MSF_fecha" },
              { filterOrderBy: true, filterTipoOrderBy: "ASC", filterCampo: "MSF_hora" }
            ])
          })
          .catch(error => {
            console.log("Realizarfichaje - createFichaje " + error)
          })
      },
      calcularEstado() {
        if (this.fichajeList.length > 0) {
          // Si hay solo uno y es de entrada-> estado = 1 (iniciada jornada)
          if (this.fichajeList[this.fichajeList.length - 1].msfichajetipo === 1) {
            this.estadoFichaje = this.estado.entrada // Entrada
            this.$store.state.userInfo.trabajando = true
          } else if (this.fichajeList[this.fichajeList.length - 1].msfichajetipo === 2) {
            this.estadoFichaje = this.estado.salida // Salida
            this.$store.state.userInfo.trabajando = false
          } else {
            this.estadoFichaje = this.estado.pausa // Pausa
            this.$store.state.userInfo.trabajando = false
          }
        } else {
          this.estadoFichaje = this.estado.inicial // Inicial
          this.$store.state.userInfo.trabajando = false
        }
      },
      checkDia() {
        var diaObj = {
          diaNumber: null,
          diaStr: '',
          diaTipo: 0,
          duracionMs: 0,
          duracionHoras: '0:00',
          jornadaCorrecta: true,
          horario: '',
          duracionJornada: '',
          tipoJornada: ''
        }
        var duracion = this.getDuracionFichaje(this.fichajeList)
        diaObj.duracionMs = duracion.duracionMs
        diaObj.duracionHoras = duracion.duracionHoras
        // Obtenemos la duracion de la jornada
        let duracionJor = 0
        if (this.horariodia.length) {
          for (let i = 0; i < this.horariodia.length; i++) {
            const horariodiaItem = this.horariodia[i]
            if (horariodiaItem.mshorariodiadiasemana === moment().day()) {
              if (horariodiaItem.mshorariodiafin1 && horariodiaItem.mshorariodiainicio1) {
                duracionJor += moment.duration(moment(horariodiaItem.mshorariodiafin1, 'hh:mm').diff(moment(horariodiaItem.mshorariodiainicio1, 'hh:mm')))
              }
              if (horariodiaItem.mshorariodiafin2 && horariodiaItem.mshorariodiainicio2) {
                duracionJor += moment.duration(moment(horariodiaItem.mshorariodiafin2, 'hh:mm').diff(moment(horariodiaItem.mshorariodiainicio2, 'hh:mm')))
              }
              diaObj.duracionJornada = this.convertirMilisegundoAHoras(duracionJor, 2)
              diaObj.horario = horariodiaItem.mshorariodiainicio1 + '-' + horariodiaItem.mshorariodiafin1 
              if (horariodiaItem.mshorariodiainicio2 !== null && horariodiaItem.mshorariodiafin2 !== null)
                diaObj.horario += ' / ' + horariodiaItem.mshorariodiainicio2 + '-' + horariodiaItem.mshorariodiafin2
              diaObj.tipoJornada = horariodiaItem.jornada.msjornadadesc
            }
          }
        }
        if (diaObj.duracionMs > duracionJor) {
          diaObj.jornadaCorrecta = true
        } else {
          diaObj.jornadaCorrecta = false
        }
        this.duracion = diaObj.duracionMs
        this.duracionActual = diaObj.duracionHoras
      },
      getDuracionFichaje (fichaje) {
        var total = {duracionMs: 0, duracionHoras: '0:00'}
        var duracion = 0
        var count = 0
        var horaAux = 0
        if (fichaje.length > 0) {
          for (let i = 0; i < fichaje.length; i++) {
            const fichajeItem = fichaje[i]
            if ((fichajeItem.msfichajetipo !== 2 && fichajeItem.msfichajetipo !== 3) || i!==0) {
              if (count === 0) { // Guardamos la primera hora
                horaAux = fichajeItem.msfichajehora
                count++
              } else { // Obtenemos la duracion del intervalo en ms
                duracion += Math.abs(moment.duration(moment(fichajeItem.msfichajehora, 'hh:mm').diff(moment(horaAux, 'hh:mm'))))
                count = 0
              }
            }
          }
        }
        if (duracion !== 0) {
          total.duracionMs = Math.abs(duracion)
          total.duracionHoras = this.convertirMilisegundoAHoras(Math.abs(duracion), 2)
        }
        return total
      },
      getPersonajornadaByFechaAndPersonaUid () {
        jornadaApiService.getPersonajornadaByFechaAndPersonaUid(moment(this.ahora).format('YYYY-MM-DD'), this.$store.state.userInfo.datos.mspersonauid)
          .then(data => {
            this.personaJornada = data
            this.getHorariodiaByJornadaId()
          })
          .catch(error => {
            console.log("getPersonajornadaByFechaAndPersonaUid " + error)
          })
        
      },
      getHorariodiaByJornadaId () {
        if (this.personaJornada.length > 0) {
          horariodiaApiService.findAllHorariodia({ jornada: { msjornadaid: this.personaJornada[0].jornada.msjornadaid } })
            .then(data => {
              // Recorremos el array, sumamos totales y ponemos par
              // let count = 0
              // for (let i = 0; i < data.length; i++) {
              //   let horariodiaItem = data[i]
              //   let duracion = 0
              //   if (horariodiaItem.mshorariodiafin1 && horariodiaItem.mshorariodiainicio1) {
              //     duracion += moment.duration(moment(horariodiaItem.mshorariodiafin1, 'hh:mm').diff(moment(horariodiaItem.mshorariodiainicio1, 'hh:mm')))
              //   }
              //   if (horariodiaItem.mshorariodiafin2 && horariodiaItem.mshorariodiainicio2) {
              //     duracion += moment.duration(moment(horariodiaItem.mshorariodiafin2, 'hh:mm').diff(moment(horariodiaItem.mshorariodiainicio2, 'hh:mm')))
              //   }
              //   horariodiaItem.total = this.convertirMilisegundoAHoras(duracion, 1)
              //   if (count === 0) {
              //     horariodiaItem.diaPar = true
              //     count++
              //   } else {
              //     horariodiaItem.diaPar = false
              //     count = 0
              //   }
              // }
              this.horariodia = data
              var diaObj = {
                horario: '',
                duracionJornada: '',
                tipoJornada: ''
              }
            
              // Obtenemos la duracion de la jornada
              let duracionJor = 0
              if (this.horariodia.length) {
                for (let i = 0; i < this.horariodia.length; i++) {
                  const horariodiaItem = this.horariodia[i]
                  if (horariodiaItem.mshorariodiadiasemana === moment().day()) {
                    if (horariodiaItem.mshorariodiafin1 && horariodiaItem.mshorariodiainicio1) {
                      duracionJor += moment.duration(moment(horariodiaItem.mshorariodiafin1, 'hh:mm').diff(moment(horariodiaItem.mshorariodiainicio1, 'hh:mm')))
                    }
                    if (horariodiaItem.mshorariodiafin2 && horariodiaItem.mshorariodiainicio2) {
                      duracionJor += moment.duration(moment(horariodiaItem.mshorariodiafin2, 'hh:mm').diff(moment(horariodiaItem.mshorariodiainicio2, 'hh:mm')))
                    }
                    diaObj.duracionJornada = this.convertirMilisegundoAHoras(duracionJor, 2)
                    diaObj.horario = horariodiaItem.mshorariodiainicio1 + '-' + horariodiaItem.mshorariodiafin1 
                    if (horariodiaItem.mshorariodiainicio2 !== null && horariodiaItem.mshorariodiafin2 !== null)
                      diaObj.horario += ' / ' + horariodiaItem.mshorariodiainicio2 + '-' + horariodiaItem.mshorariodiafin2
                    diaObj.tipoJornada = horariodiaItem.jornada.msjornadadesc
                  }
                }
              }
              this.horario = diaObj.horario
              this.$store.state.userInfo.minutosATrabajar = this.convertirHorasAMinutos(diaObj.duracionJornada)
              // this.checkDia()
            })
            .catch(error => {
              console.log('getHorariodiaByJornadaId ' + error)
            })
        }
      },
      convertirMilisegundoAHoras(milisegundos, tipo) {
        switch (tipo) {
          case 1:
            return moment()
              .startOf("day")
              .millisecond(milisegundos)
              .format("H [h] mm [min]")
          case 2:
            return moment()
              .startOf("day")
              .millisecond(milisegundos)
              .format("H:mm")
          default:
            return moment()
              .startOf("day")
              .millisecond(milisegundos)
              .format("H:mm")
        }
      },
      convertirHorasAMinutos(horas) {
        return moment.duration(horas,'HH:mm').asMinutes();
      }, 
      updateUserLastActivity() {
        chatApiService.updateUserLastActivity(this.$store.state.userInfo.chatAgentId)
          .then(() => {
            // console.log('activity')
            // console.log(data)
          })
          .catch(() => {
            // console.log(error)
            this.snackbar = true;
            this.text = 'Error actualizar activity'
          })
      }
    }
  })
</script>


