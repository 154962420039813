import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import TimeControl from '../views/TimeControl.vue'
import MobileLogin from '../views/MobileLogin.vue'
import HomeBI from '../views/BI/HomeBI.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/tienda',
    name: 'Home',
    component: Home
  },
  {
    path: '/',
    name: 'login',
    component: MobileLogin
  },
  {
    path: '/timecontrol',
    name: 'TimeControl',
    component: TimeControl
  },
  {
    path: '/mobilelogin',
    name: 'MobileLogin',
    component: MobileLogin
  },
  // routa para las instalaciones de sys4
  {
    path: '/mobilelogin/:instalacion',
    name: 'LoginSys4',
    component: MobileLogin,
    props: true
  },
  {
    path: '/BI',
    name: 'BI',
    component: HomeBI
  },
  {
    path: '/OrderDetail',
    name: 'OrderDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/OrderDetail.vue')
  },
  {
    path: '/inventario',
    name: 'Inventario',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Inventario.vue')
  },
  {
    path: '/inventariototal',
    name: 'InventarioTotal',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/InventarioTotal.vue')
  },
  {
    path: '/Workflow',
    name: 'Workflow',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Workflow.vue')
  },
  {
    path: '/Link',
    name: 'Link',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Link.vue')
  },
  {
    path: '/Note',
    name: 'Note',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Note.vue')
  },
  {
    path: '/productpage/:productId',
    name: 'ProductPage',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ProductPage.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
  path: '/shop/ticket',
  name: 'Ticket',
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  component: () => import(/* webpackChunkName: "about" */ '../views/Ticket.vue')
  },
  {
    path: '/timeLine',
    name: 'TimeLine',
    component: () => import('../views/TimeLine.vue')
  },

  {
    path: '/profile',
    name: 'Profile',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Profile.vue')
  },
  {
    path: '/calendar',
    name: 'Calendar',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Calendar.vue')
  },
  {
    path: '/chat',
    name: 'Chat',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Chat.vue')
  },
  {
    path: '/chatdetail/:conversationId/:userId/:agentId',
    name: 'ChatDetail',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ChatDetail.vue')
  },
  {
    path: '/order',
    name: 'Order',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Order.vue')
  },
  {
    path: '/logout',
    name: 'Logout',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Logout.vue')
  },
  {
    path: '/shop/search',
    name: 'Search',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Search.vue')
  },
  
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['LoginSys4', 'login', 'MobileLogin', 'Logout',];
  const authRequired = !publicPages.includes(to.name);
  const loggedIn = localStorage.getItem('user');

  if (authRequired && !loggedIn) {
    if (authRequired){
      if (process.env.VUE_APP_ISSYS4CONJUNTO == 'false') {
        next('/mobilelogin');
      } else {
        // next()
      }
    } else {
      comprobarPermiso(to.name);
    }
  } else {
    comprobarPermiso(to.name);
  }
  //miramos los permisos del usuario si puede acceder a las páginas por si meten la url por el navegador
  function comprobarPermiso(direccion) {
    switch (direccion) {
      case 'Home':
        if (Vue.prototype.permitido('MOBILESTORE.PHONESERVICES.TIENDA')) {
          next()
        }
        break
      case 'Calendar':
        if (Vue.prototype.permitido('MOBILESTORE.PHONESERVICES.CALENDARIO')) {
          next()
        }
        break
      case 'TimeControl':
        if (Vue.prototype.permitido('MOBILESTORE.PHONESERVICES.CONTROLHORARIO')) {
          next()
        }
        break
      case 'TimeLine':
        if (Vue.prototype.permitido('MOBILESTORE.PHONESERVICES.TIMELINE') && !Vue.prototype.isSys4()) {
          next()
        }
        break
      case 'Chat':
        if (Vue.prototype.permitido('MOBILESTORE.PHONESERVICES.CHAT')) {
          next()
        }
        break
      case 'BI':
        if (Vue.prototype.permitido('MOBILESTORE.PHONESERVICES.ESTADISTICAS')) {
          next()
        }
        break
      case 'Link':
        if (Vue.prototype.permitido('MOBILESTORE.PHONESERVICES.WEBLINKS') && !Vue.prototype.isSys4()) {
          next()
        }
        break
      case 'Note':
        if (Vue.prototype.permitido('MOBILESTORE.PHONESERVICES.NOTAS') && !Vue.prototype.isSys4()) {
          next()
        }
        break
      case 'Workflow':
        if (Vue.prototype.permitido('MOBILESTORE.PHONESERVICES.WORKFLOW') && !Vue.prototype.isSys4()) {
          next()
        }
        break
      case 'Inventario':
        if (Vue.prototype.permitido('MOBILESTORE.PHONESERVICES.MODSTOCK')) {
          next()
        }
        break
      case 'InventarioTotal':
        if (Vue.prototype.permitido('MOBILESTORE.PHONESERVICES.INVENTARIO')) {
          next()
        }
        break
      default:
        next()
    }
  }
})

export default router

