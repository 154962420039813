import axios from 'axios'
const URL_API_PS = process.env.VUE_APP_URL_API_PS

export class PsApiService {
  getRankingTop20 (intervalo, seccion, opUid) {
    let config = {
      headers: { 'Authorization': localStorage.getItem('token'), 'instalacion' : localStorage.getItem("instalacion") }
    }
    const url = `${URL_API_PS}getRankingTop20/${intervalo}/${seccion}/${opUid}`
    return axios
      .get(url, config)
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        if (error.response.status === 401 || error.response.status === 403) {
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error
      })
  }
  getVentaNeta (intervalo, tipoTienda, userUid) {
    let config = {
      headers: { 'Authorization': localStorage.getItem('token'), 'instalacion' : localStorage.getItem("instalacion") }
    }
    const url = `${URL_API_PS}getVentaNeta/${intervalo}/${tipoTienda}/${userUid}`
    return axios
      .get(url, config)
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        if (error.response.status === 401 || error.response.status === 403) {
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error
      })
  }
  getAnalisisTienda (intervalo, tipoTienda, userUid) {
    let config = {
      headers: { 'Authorization': localStorage.getItem('token'), 'instalacion' : localStorage.getItem("instalacion") }
    }
    const url = `${URL_API_PS}getAnalisisTienda/${intervalo}/${tipoTienda}/${userUid}`
    return axios
      .get(url, config)
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        if (error.response.status === 401 || error.response.status === 403) {
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error
      })
  }
  getAnalisisSeccion (intervalo, userUid) {
    let config = {
      headers: { 'Authorization': localStorage.getItem('token'), 'instalacion' : localStorage.getItem("instalacion") }
    }
    const url = `${URL_API_PS}getAnalisisSeccion/${intervalo}/${userUid}`
    return axios
      .get(url, config)
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        if (error.response.status === 401 || error.response.status === 403) {
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error
      })
  }
  regeneraTablas () {
    let config = {
      headers: { 'Authorization': localStorage.getItem('token'), 'instalacion' : localStorage.getItem("instalacion") }
    }
    const url = `${URL_API_PS}regenerarTablas`
    return axios
      .get(url, config)
      .then(function (response) {
        return response
      })
      .catch(function (error) {
        if (error.response.status === 401 || error.response.status === 403) {
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error
      })
  }
  getAllSeccion () {
    let config = {
      headers: { 'Authorization': localStorage.getItem('token'), 'instalacion' : localStorage.getItem("instalacion") }
    }
    const url = `${URL_API_PS}getAllSeccion`
    return axios
      .get(url, config)
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        if (error.response.status === 401 || error.response.status === 403) {
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error
      })
  }
  getAllTipoTiendaAnalisis () {
    let config = {
      headers: { 'Authorization': localStorage.getItem('token'), 'instalacion' : localStorage.getItem("instalacion") }
    }
    const url = `${URL_API_PS}getAllTipoTiendaAnalisis`
    return axios
      .get(url, config)
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        if (error.response.status === 401 || error.response.status === 403) {
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error
      })
  }
  getAllTipoTiendaVenta () {
    let config = {
      headers: { 'Authorization': localStorage.getItem('token'), 'instalacion' : localStorage.getItem("instalacion") }
    }
    const url = `${URL_API_PS}getAllTipoTiendaVenta`
    return axios
      .get(url, config)
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        if (error.response.status === 401 || error.response.status === 403) {
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error
      })
  }
  getAllPermisos () {
    let config = {
      headers: { 'Authorization': localStorage.getItem('token'), 'instalacion' : localStorage.getItem("instalacion") }
    }
    const url = `${URL_API_PS}getAllPermisos`
    return axios
      .get(url, config)
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        if (error.response.status === 401 || error.response.status === 403) {
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error
      })
  }
}
