<template>
  <v-app>
    <v-main class="background d-flex align-center login justify-center">    
       
        <v-img
        class="mb-7 flex-grow-0"
          :src="(!isSys4Conjunto()) ? require('../assets/logo_mobile_store.svg') : require('../assets/logo_mobileStore_sys4.png')"
          contain
          height="75"/>
        <v-tabs fixed-tabs centered color="secondary" class="flex-grow-0">
            <v-tab fixed-tabs>
              Acceder a mi cuenta 
            </v-tab>
            <!-- <v-tab fixed-tabs>
              Recordar contraseña
            </v-tab> -->
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                   <form class="login">
                    <v-text-field class="email"
                    label="Usuario"
                    color="accent"
                    v-model="usuario"
                    prepend-inner-icon=" "
                    ></v-text-field>
                    <v-text-field 
                        class="password"
                        v-model="password"
                        label="Contraseña"
                        color="accent"
                        prepend-inner-icon=" "
                        :type="show1 ? 'text' : 'password'"
                    ></v-text-field>
                    <v-btn
                      @click="login()"
                      tile
                      block
                      depressed
                      color="primary" >
                      Acceder
                    </v-btn>
                  </form>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <!-- <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <form class="login">
                  <p>
                  Proporciona tu direección de correo electrónico y te eniviaremos un email con instrucciones de cómo recuperarla.
                  </p>
                  <v-text-field class="user"
                      label="Usuario"
                      color="accent"
                      prepend-inner-icon=" "
                       
                  ></v-text-field>
                    <v-btn
                      v-if="permitido('MOBILESTORE.CLIENTELING')"
                      tile
                      block
                      depressed
                      color="primary" >
                      Acceder
                    </v-btn>
                    </form>
                </v-card-text>
              </v-card>
            </v-tab-item> -->
          </v-tabs> 
          <!-- snackbar para mensajes en pantalla -->
          <div class="text-center">
            <v-snackbar
              v-model="snackbar"
              :multi-line="multiLine"
              color="red lighten-1"
            >
              {{ text }}

              <template v-slot:action="{ attrs }">
                <v-btn
                  fab
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
            </v-snackbar>
          </div>
          <!--  -->
    </v-main>
  </v-app> 
</template>
<script lang="ts">
  import Vue from 'vue'
  import { ChatApiService } from '../api/ChatApiService'
  import {PersonaApiService} from '../api/PersonaApiService'
  const personaApiService = new PersonaApiService()

  const chatApiService = new ChatApiService()
  export default Vue.extend({
    name: 'login',
    props: ['instalacion'],
    data () {
      
      return {
        show1: false,
        usuario: '',
        password: '',
        multiLine: true,
        snackbar: false,
        text: '',
        sesion: null
      }
     
    },
    created () {
      // this.sesion = localStorage.getItem('idSesion');
      // if (this.sesion == null) {
      //     this.sesion = this.uniqid();
      //     localStorage.setItem('idSesion', this.sesion);
      // } else {
      //     console.log(this.sesion);
      // }
      // this.emitirPing()

      if (Vue.prototype.isSys4Conjunto()) {
        if (this.instalacion === null || this.instalacion ==='' || this.instalacion ===  undefined) {
          this.snackbar = true;
          this.text = 'Falta el nombre de la instalacion en la url'
        } 
      } else {
        this.instalacion = ''
      }
      // if (this.$store.state.userInfo.isSignedUp) {
      //   if (Vue.prototype.isSys4Conjunto()) { 
      //     this.$router.push({name : 'InventarioTotal' })
      //   } else {
      //     this.$router.push({name : 'TimeControl' })
      //   }
      // }
    },
    methods: {
      login() {
          this.$store.dispatch('login', { user: this.usuario, password: this.password, instalacion: this.instalacion })
            .then(() => {
              //cogemos los datos de la persona
              this.getPersonaById()
            })
            .catch(error => {
              console.log(error)
              this.snackbar = true;
              this.text = error
            })
          this.$store.dispatch('saveLang', 'es')
        
      },
      conectarUsuario() {
        var data = { sesion: this.$store.getters.getSesion, idOauth: this.$store.state.userInfo.uid, aplicacion: 2, instalacion: this.instalacion };
        this.$socket.emit('usuario', data)
      },
      // emitirPing() {
      //   var vue = this
      //   setInterval( function() {
      //     vue.$socket.emit("ping", this.sesion);
      //   }, 5000);
      // },
      loginChat() {
        chatApiService.postLogin(this.$store.state.userInfo.datos.mspersonanombre)
          .then((data) => {
            if (data[1].length > 1) {
              localStorage.setItem('tokenChat',data[1][1])
              //una vez logueado en el chat actualizamos el id de agente de supportboard en la tabla persona
              this.updatePersonaChatId(data[1][0].id)
            } else {
              this.snackbar = true;
              this.text = 'Error de credenciales'
            }
          })
          .catch((error) =>{
            this.snackbar = true;
            this.text = error
          })
      },
      getPersonaById() {
        if (this.$store.state.userInfo.id != '' && this.$store.state.userInfo.id != undefined) {
          personaApiService.findPersona({ mspersonaid: this.$store.state.userInfo.id })
            .then(data => {
              if (data) {
                this.conectarUsuario()
                  this.$store.commit('setPersona', data)
                  // this.loginChat()
                  if (Vue.prototype.isSys4Conjunto()) { 
                    this.$router.push({name : 'InventarioTotal' })
                  } else {
                    this.$router.push({name : 'TimeControl' })
                  }
                // } else {
                //   this.snackbar = true;
                //   this.text = 'Ya hay una sesión inciada'
                // }
              } else {
                this.snackbar = true;
                this.text = 'Datos de usuario no encrontrado'
              }
            })
            .catch(error => {
              console.log("App - getPersonaById" + error)
              this.snackbar = true;
              this.text = 'Ha ocurrido un error'
            });
        } else {
          this.snackbar = true;
          this.text = 'Datos de usuario no encrontrado'
        }
      },
      updatePersonaChatId(id) {
        let persona = { 
          mspersonausumodif: this.$store.state.userInfo.name,
          mspersonaid: this.$store.state.userInfo.id,
          mspersonachatid: id,
        }
        personaApiService.updatePersona(persona)
          .then(data => {
            if (data.status === 200) {
              this.$store.commit('setChatId', id)
            }
          })
          .catch(error => {
            this.snackbar = true;
            this.colorSnack = 'red lighten-1'
            this.text = 'Ha ocurrido un error'
            console.log('updatePersonaChatId' + error)
          })
      },
      uniqid(prefix = "", random = false) {
        const sec = Date.now() * 1000 + Math.random() * 1000;
        const id = sec.toString(16).replace(/\./g, "").padEnd(14, "0");
        return `${prefix}${id}${random ? `.${Math.trunc(Math.random() * 100000000)}`:""}`;
      }
    }
  })
</script>


