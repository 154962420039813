<template>
<v-app >
 
      <v-app-bar elevation="0" class="pl-16" color="white">
            <Header/>
      </v-app-bar>
        <Menu /> 
  <v-main>
    <v-card class="statistics">
    <v-toolbar
      flat
      color="primary"
      dark
    >
    
      <v-toolbar-title>User Profile</v-toolbar-title>
    </v-toolbar>
    <v-tabs show-arrows >
      <v-tab>
       Venta neta
      </v-tab>
       <v-tab>
        Ranking
      </v-tab>
      <v-tab>
        Análisis Tienda
      </v-tab>
    <v-tab>
        Análisis Sección
      </v-tab> 
     
      <v-tab-item>
       
          <v-card-text class="pa-0">
           <Neta/> 
          </v-card-text>
      
      </v-tab-item>
       <v-tab-item>
        <v-card flat>
          <v-card-text class="pa-0">
           <Ranking/> 
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text class="pa-0">
            <Tienda/> 
          </v-card-text>
        </v-card>
      </v-tab-item>
        <v-tab-item>
        <v-card flat>
          <v-card-text class="pa-0">
            <Seccion/>  
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
  <!-- <v-container fluid fill-height id="home" class="mt-10">
    <v-layout>
      <v-row>
        <v-col cols="12" sm="12">
          <v-row>
            <v-col cols="6" sm="3" md="2">
              <v-card flat to="ventaneta" class="card-home ">
                <v-img class="home-imagen"
                  :src="require('@/assets/images/ventaNeta.png')"
                >
                </v-img>
                 <v-card-title class="primary--text text-uppercase">
                  Venta neta
                </v-card-title>
              </v-card>
            </v-col>
            <v-col cols="6" sm="3" md="2">
              <v-card flat to="ranking" class="card-home">
                <v-img class="home-imagen"
                  :src="require('@/assets/images/ranking.png')"
                >
                </v-img>
                 <v-card-title  class="primary--text text-uppercase">
                  Ranking
                </v-card-title>
              </v-card>
            </v-col>
            <v-col cols="6" sm="3" md="2">
              <v-card flat to="analisistienda" class="card-home">
                <v-img class="home-imagen"
                  :src="require('@/assets/images/ventaTienda.png')"
                >
                </v-img>
                 <v-card-title  class="primary--text text-uppercase">
                  Análisis tienda
                </v-card-title>
              </v-card>
            </v-col>
            <v-col cols="6" sm="3" md="2">
              <v-card flat tile to="analisisseccion">
                <v-img class="home-imagen"
                  :src="require('@/assets/images/ventaSecciones.png')"
                >
                </v-img>
                 <v-card-title  class="primary--text text-uppercase">
                  Análisis seccion
                </v-card-title>
              </v-card>-->
           <!-- </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-layout>
  </v-container>-->
  </v-main>
</v-app>
</template>
<script>
import Menu from '../../components/Menu.vue'
import Header from '../../components/Estadisticas/EHeader.vue'
import Seccion from '../../components/Estadisticas/AnalisisSeccion.vue'
import Tienda from '../../components/Estadisticas/AnalisisTienda.vue'
import Ranking from '../../components/Estadisticas/Ranking.vue'
import Neta from '../../components/Estadisticas/VentaNeta.vue'



export default {
  components: {
    Menu,
    Header,
    Seccion,
    Tienda,
    Ranking,
    Neta
 
 
  },
  data () {
    return {
      persona: {},
      fichajeList: [],
      estado: '',
      estadoId: 0,
      horaInicial: '',
      totalHoras: '',
      dateContextTxt: ''
    }
  },
  beforeCreate () {
    // console.log(this.$store.getters.isRegenerarTablas)
    // this.$store.dispatch('regeneraTablas')
    // if (this.$store.getters.isUserTwister) {
    //   this.$store.dispatch('getPermisos')
    // }
    // console.log(this.$store.getters.getPermisos[1])
  },
  computed: {
    // regenerar () {
    //   return this.$store.getters.isRegenerarTablas
    // }
  },
  watch: {
    // regenerar (value) {
    //   console.log(`My store value for 'regenerar' changed to ${value}`)
    // }
  }
}
</script>
