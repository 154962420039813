<template>
<div>
  <v-toolbar class="elevation-0 col-12 pa-0 ma-0 align-center pr-2">
      <v-col class="d-flex py-0 align-center justify-space-between">
        <v-text-field
          class="my-3"
          label="Buscar cliente"
          v-model="vBusquedaCliente"
          hide-details="auto"
        ></v-text-field> 
        <v-btn class="icon search" icon large @click="buscarCliente()"> </v-btn>                 
        <!-- <v-autocomplete
            v-model="friends"
            :items="people"
            append-outer-icon=" "
            chips
            color="primary"
            label="Cliente asociado"
            placeholder="No hay cliente asociado"
            item-text="name"
            item-value="name"
            class="user2 pt-4"

          >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              close
              color="secondary"
              @click="data.select"
              @click:close="remove(data.item)"
            >
              {{ data.item.name }}
            </v-chip>
          </template>
          <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title class="primary--text font-weight-medium text-uppercase" v-html="data.item.name"></v-list-item-title>
                <v-list-item-subtitle v-html="data.item.email"><v-icon class=""></v-icon></v-list-item-subtitle>
                <v-list-item-subtitle v-html="data.item.tlf"></v-list-item-subtitle>
              </v-list-item-content>
          </template>
        </v-autocomplete> -->
      </v-col>
      <v-btn icon large color="primary" @click="$emit('cerrar')"><v-icon>mdi-close</v-icon> </v-btn>
  </v-toolbar>
  <v-list 
    three-line
    >
      <v-list-item-group
        v-model="vCliente"
        color="primary"
        @change="selecCliente()"
      >
        <template v-for="(cliente, index) in clientes">
          <v-divider
            v-if="index !== 0"
            :key="index + '-'"
          ></v-divider>

          <v-list-item
            :key="index"
            :value="cliente"
          >
            <v-list-item-content>
              <v-list-item-title v-html="cliente.nombre"></v-list-item-title>
              <v-list-item-subtitle v-html="cliente.email"></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list-item-group>  
    <div class="text-center">
      <v-pagination
        v-if="totalPag !==0"
        v-model="page"
        :length="totalPag"
        total-visible="6"
        @input="buscarCliente"
      ></v-pagination>
    </div>
  </v-list>
</div>
</template>

<script lang="ts">
  import Vue from 'vue'
  import { ClienteApiService } from '../../api/ClienteApiService';
  const clienteApi = new ClienteApiService()

  export default Vue.extend({
    name: 'userselect',
    data: () => ({
      vBusquedaCliente: '',
      clientes: [],
      vCliente: {},
      page: 0,
      totalPag: 0,
      //  friends: 'carlota@yet.es',
      //  people: [
      //     { name: 'Sandra Adams', email: 'email@email.com', tlf: '666 666 666', },
      //     { name: 'Ali Connors', email: 'email@email.com',  tlf: '666 666 666', },
      //     { name: 'Trevor Hansen', email: 'email@email.com', tlf: '666 666 666', },
      //     { name: 'Tucker Smith', email: 'email@email.com', tlf: '666 666 666', },
      //     { name: 'Britta Holt', email: 'email@email.com', tlf: '666 666 666', },
      //     { name: 'Jane Smith ', email: 'email@email.com', tlf: '666 666 666', },
      //     { name: 'John Smith', email: 'email@email.com', tlf: '666 666 666', },
      //     { name: 'Sandra Williams', email: 'email@email.com', tlf: '666 666 666', },
      //   ],
    }),
    methods: {
      // remove (item) {
      //   const index = this.friends.indexOf(item.name)
      //   if (index >= 0) this.friends.splice(index, 1)
      // },
      buscarCliente() {
        clienteApi.getClientesFilter(this.vBusquedaCliente, this.page)
        .then( data => {
          if (data.content.length == 0)
            this.clientes = [];
          else  {
            this.clientes = data.content;
            this.totalPag = data.totalPages;
          }
        })
        .catch( error => {
          this.clientes = []
          console.log(error)
        })
      },
      selecCliente() {
        console.log(this.vCliente)
        this.$emit('cerrar')
        this.$store.dispatch("setCliente", this.vCliente)
      }
    },
  })
</script>
