import axios from 'axios'
const URL_API_JORNADA = process.env.VUE_APP_URL_API_JORNADA
const URL_API_PERSONAJORNADA = process.env.VUE_APP_URL_API_PERSONAJORNADA

export class JornadaApiService {
  /**
   * Devuelve todas las jornadas
   * @return Pais[]
   */
  getAllJornadas () {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = URL_API_JORNADA + 'getAllJornadas'
    return axios
      .get(url, config)
      .then(function (response) {
        return response.data
      })
      .catch(function(error) {
        if (error.response.status===401 || error.response.status===403){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }

  getJornadaById (msjornadaid) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = URL_API_JORNADA + 'getJornadaById/' + msjornadaid
    return axios
      .get(url, config)
      .then(function (response) {
        return response.data
      })
      .catch(function(error) {
        if (error.response.status===401 || error.response.status===403){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }

  getJornadaByAnhoAndPersonaId (anho, mspersonajornadapersonaid) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = URL_API_JORNADA + 'getJornadaByAnhoAndPersonaId/' + anho + '/' + mspersonajornadapersonaid
    return axios
      .get(url, config)
      .then(function (response) {
        return response.data
      })
      .catch(function(error) {
        if (error.response.status===401 || error.response.status===403){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }

  getJornadaByFechaAndPersonaUid (fecha, mspersonajornadapersonauid) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = URL_API_JORNADA + 'getJornadaByFechaAndPersonaId/' + fecha + '/' + mspersonajornadapersonauid
    return axios
      .get(url, config)
      .then(function (response) {
        return response.data
      })
      .catch(function(error) {
        if (error.response.status===401 || error.response.status===403){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }

  getPersonajornadaByPersonaUid (mspersonajornadapersonauid) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = URL_API_PERSONAJORNADA + 'getPersonajornadaByPersonaUid/' + mspersonajornadapersonauid
    return axios
      .get(url, config)
      .then(function (response) {
        return response.data
      })
      .catch(function(error) {
        if (error.response.status===401 || error.response.status===403){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }

  getPersonajornadaByAnhoPersonaUid (anho, mspersonajornadapersonauid) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = URL_API_PERSONAJORNADA + 'getPersonajornadaByAnhoPersonaUid/' + anho + '/' + mspersonajornadapersonauid
    return axios
      .get(url, config)
      .then(function (response) {
        return response.data
      })
      .catch(function(error) {
        if (error.response.status===401 || error.response.status===403){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }

  getPersonajornadaByFechaAndPersonaUid (fecha, mspersonajornadapersonauid) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = URL_API_PERSONAJORNADA + 'getPersonajornadaByFechaAndPersonaUid/' + fecha + '/' + mspersonajornadapersonauid
    return axios
      .get(url, config)
      .then(function (response) {
        return response.data
      })
      .catch(function(error) {
        if (error.response.status===401 || error.response.status===403){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }
}
