import Vue from 'vue'
import axios from 'axios';
const URL_API_TICKET = process.env.VUE_APP_URL_API_TICKET;

export class TicketApiService {
  
  getAllTicketAparOrderByOpuid(opuid) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    let url = `${URL_API_TICKET}getAllTicketAparOrderByOpuid/${opuid}`;
    if (Vue.prototype.isSys4Conjunto()) {
      if (localStorage.getItem("instalacion") === null || localStorage.getItem("instalacion") === '') {
        window.location.href = process.env.BASE_URL + '#/logout'
      } else {
        url = url.replace("instalacion", localStorage.getItem("instalacion"))
      }
    }
    return axios
      .get(url,config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response.status===401 || error.response.status===403){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }

  deleteTicket(cabecera) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    let url = `${URL_API_TICKET}deleteTicket`;
    if (Vue.prototype.isSys4Conjunto()) {
      if (localStorage.getItem("instalacion") === null || localStorage.getItem("instalacion") === '') {
        window.location.href = process.env.BASE_URL + '#/logout'
      } else {
        url = url.replace("instalacion", localStorage.getItem("instalacion"))
      }
    }
    return axios
      .delete(url, { data: cabecera }, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response.status===401 || error.response.status===403){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }

  addTicket(ticket) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    let url = `${URL_API_TICKET}addTicket`;
    if (Vue.prototype.isSys4Conjunto()) {
      if (localStorage.getItem("instalacion") === null || localStorage.getItem("instalacion") === '') {
        window.location.href = process.env.BASE_URL + '#/logout'
      } else {
        url = url.replace("instalacion", localStorage.getItem("instalacion"))
      }
    }
    return axios
      .post(url, ticket, config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response.status===401 || error.response.status===403){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }
  findTicket(filtro) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    let url = `${URL_API_TICKET}findTicket`;
    if (Vue.prototype.isSys4Conjunto()) {
      if (localStorage.getItem("instalacion") === null || localStorage.getItem("instalacion") === '') {
        window.location.href = process.env.BASE_URL + '#/logout'
      } else {
        url = url.replace("instalacion", localStorage.getItem("instalacion"))
      }
    }
    return axios
      .post(url, filtro,config)
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        if (error.response.status===401 || error.response.status===403){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }
  getDiasReserva() {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    let url = `${URL_API_TICKET}getDiasReserva`;
    if (Vue.prototype.isSys4Conjunto()) {
      if (localStorage.getItem("instalacion") === null || localStorage.getItem("instalacion") === '') {
        window.location.href = process.env.BASE_URL + '#/logout'
      } else {
        url = url.replace("instalacion", localStorage.getItem("instalacion"))
      }
    }
    return axios
      .get(url,config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response.status===401 || error.response.status===403){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }
  getStockReservadoByEansOps(codigosEan,ops) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    let url = `${URL_API_TICKET}getStockReservadoByEansOps/${codigosEan}/${ops}`;
    if (Vue.prototype.isSys4Conjunto()) {
      if (localStorage.getItem("instalacion") === null || localStorage.getItem("instalacion") === '') {
        window.location.href = process.env.BASE_URL + '#/logout'
      } else {
        url = url.replace("instalacion", localStorage.getItem("instalacion"))
      }
    }
    return axios
      .get(url,config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response.status===401 || error.response.status===403){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }
}