<template>
  <v-card flat>
    <v-card-text class="pa-0">
      <v-list class="pa-0 cart-list">
        <v-list-item-group>
          <v-list-item v-for="product in products" :key="product.id">
            <v-img v-bind:src="product.foto" class="white--text align-end" max-height="80" max-width="60" contain> </v-img>
            <v-list-item-content class="pl-3">
              <v-list-item-title class="text-uppercase primary--text font-weight-medium" v-text="product.modelo + '-' + product.color"></v-list-item-title>
              <v-list-item-subtitle class="text-uppercase accent--text" v-text="product.codigo"></v-list-item-subtitle>
              <div class="d-flex align-center justify-space-between">
                <p class="text-truncate pt-2 font-weight-light mb-0 pa-0 caption d-flex text-truncate">Talla: {{ product.talla }}</p>
                <p class="mb-0">{{product.cantidad}}und</p>
                <p class="font-weight-bold mb-0 text-h6 primary--text">{{product.precio}}€</p>
                <v-btn icon small @click="removeFromCart(product.id, product.codigo)"><v-icon>mdi-delete</v-icon> </v-btn>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card-text>
    <v-row class="mx-0 total-price">
      <v-col class="pr-15 mr-10 primary--text  d-flex flex-column align-end">
        <p class="mb-0">Total impuestos incluidos</p>
        <p class="text-h4 mb-4 font-weight-bold text-">{{ total }}€</p>
      </v-col>
    </v-row>
    <v-btn class="icon guardar" @click="aparcarTicket()" fab color="secondary" large elevation="0" bottom absolute right></v-btn>
    <v-snackbar v-model="snackbar" :multi-line="multiLine" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn fab text v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>  
  </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import { TicketApiService } from '../../api/TicketApiService';
const ticketApiService = new TicketApiService();

export default Vue.extend({
  name: 'Back',
  data() {
    return {
      cartlist: [],
      tiposTicket: [
        { nombre: 'Tienda', id: 0 },
        { nombre: 'Reserva', id: 1 },
      ],
      vTipo: 0,
      reiniciarFecha: false,
      showFechaReserva: false,
      snackbar: false,
      multiLine: true,
      snackColor: '',
      snackText: '',
    };
  },
  props: {
    idCliente: {
        type: String,
        required: false,
        default: ''
    }
  },
  computed: {
    products() {
      return this.$store.getters.getProductsCart;
    },
    subtotal() {
      let subtotal = 0;
      this.$store.getters.getProductsCart.forEach((element) => {
        subtotal += element.precio * element.cantidad;
      }, subtotal);
      return subtotal.toFixed(2);
    },
    total() {
      let total = 0;
      this.$store.getters.getProductsCart.forEach((element) => {
        total += element.precio * element.cantidad;
      }, total);
      return total.toFixed(2);
    },
    totalUds() {
      let totalUds = 0;
      this.$store.getters.getProductsCart.forEach((element) => {
        totalUds += element.cantidad;
      }, totalUds);
      return totalUds;
    },
    // cliente() {
    //   return this.$store.getters.getCliente;
    // },
  },
  watch: {
    products(value) {
      if (value.length < 1) {
        this.$store.commit('showCarritoModal', false);
      }
    },
    // cliente(value) {
    //   if (value !== null) {
    //     this.idCliente = value.email;
    //   } else {
    //     this.idCliente = null;
    //   }
    // },
  },
  created() {
    // if (localStorage.getItem('tipoTicket') === null) {
    //   this.vTipo = 0;
    // } else {
    //   this.vTipo = parseInt(localStorage.getItem('tipoTicket'));
    // }
    // if (localStorage.getItem('idClienteTicket') === null) {
    //   if (this.cliente !== null) {
    //     this.idCliente = this.cliente.email;
    //   } else {
    //     this.idCliente = '';
    //   }
    // } else {
    //   this.idCliente = localStorage.getItem('idClienteTicket');
    // }
    // if (localStorage.getItem('fechaTicket') === null) {
    //   this.showFechaReserva = false;
    // } else {
    //   this.showFechaReserva = true;
    // }
  },
  methods: {
    removeFromCart(id, codigo) {
      let data = {
        id: id,
        codigo: codigo,
      };
      this.$store.commit('removeFromCart', data);

      // this.$store.commit('setAddedBtn', data);
    },
    aparcarTicket() {
      if (this.$store.getters.getProductsCart.length !== 0) {
        var ticketAux = {
          cabecera: {
            msct_opuid: this.$store.getters.getOpuid,
            msct_tipo: this.vTipo,
            msct_puesto: '000',
            msct_id_cliente: this.idCliente,
          },
        };
        if (localStorage.getItem('fechaTicket') !== null && this.vTipo === 1) {
          if (!this.reiniciarFecha) {
            Object.defineProperty(ticketAux.cabecera, 'msct_fecha', { value: localStorage.getItem('fechaTicket') });
          }
        }
        let lineasTicketAux = {
          lineasticket: [],
        };
        this.$store.getters.getProductsCart.forEach((element) => {
          let lineaTicketAux = {
            mslt_codigo_escaneo: element.codigo,
            mslt_precio: element.precio,
            mslt_descuento: 0,
            mslt_unidades: element.cantidad,
          };
          lineasTicketAux.lineasticket.push(lineaTicketAux);
        }, lineasTicketAux);
        Object.assign(ticketAux, lineasTicketAux);
        //ticketAux.push(lineasTicketAux)
        ticketApiService
          .addTicket(ticketAux)
          .then(() => {
            this.$root.$emit('actualizarTickets')
            this.snackbar = true
            this.snackColor = 'green'
            this.snackText = 'Ticket guardado con éxito'
            this.limpiar()
            localStorage.removeItem('tipoTicket');
            localStorage.removeItem('idClienteTicket');
            localStorage.removeItem('fechaTicket');

          })
          .catch((error) => {
            this.snackbar = true
            this.snackColor = 'red darken-2'
            this.snackText = 'Ha ocurrido un error'
            console.log('ticketApiService - addTicket ' + error);
          })
      }
    },
    limpiar() {
      this.showFechaReserva = false;
      localStorage.removeItem('tipoTicket');
      localStorage.removeItem('idClienteTicket');
      localStorage.removeItem('fechaTicket');
      this.$store.state.tienda.productsCart = [];
    },
  },
});
</script>
