<template>
  <v-row class=" ma-0 pr-3">
    <div class="text-center ml-auto">
      <v-dialog transition="dialog-top-transition" max-width="600" fullscreen ref>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="icon search" icon large v-bind="attrs" v-on="on" solo-inverted></v-btn>
        </template>
        <template v-slot:default="dialog">
          <v-card>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="icon user" icon large v-bind="attrs" v-on="on"> </v-btn>
            </template>
            <v-card class="search-dialog elevation-0">
              <v-card-text class="d-flex px-0 pa-0 flex-column">
                <v-toolbar class="col-12 pa-0 ma-0 align-center pr-2 elevation-0">
                  <v-col class="d-flex py-0 align-center justify-space-between ">
                    <v-text-field class="buscar mr-3 d-flex pt-4" label="Modelo / Localizador" v-model="localizador" @keyup.enter="searchByLocalizador(); dialog.value = false"></v-text-field>
                  </v-col>
                  <v-btn class="icon search" icon large @click="searchByLocalizador(); dialog.value = false"> </v-btn>
                  <v-btn
                    color="primary"
                    icon
                    top
                    right
                    text
                    @click="
                      dialog.value = false;
                      reset();
                    "
                    ><v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-row class="col-12 pb-0 px-2 ma-0 padding">
                  <v-col class="col-12 ma-0 py-0"> </v-col>
                </v-row>
                <v-row class="col-12 pa-0 ma-0 scan">
                  <v-col class="primary col-12 pa-0">
                    <Scan @search-by-ean-code="searchByEanCode" />
                  </v-col>
                </v-row>
                <v-list class="pa-0">
                  <v-subheader class="text-uppercase">Historial de últimas busquedas</v-subheader>
                  <v-list-item-group v-model="selectedItem" color="primary" style="height: 31vh" class="overflow-y-auto">
                    <divider></divider>
                    <v-list-item v-for="(item, i) in searchList" :key="i">
                      <v-img v-bind:src="item.src" class="white--text align-end " max-height="80" max-width="90" contain> </v-img>
                      <v-list-item-content class="pl-3">
                        <v-list-item-title class="text-uppercase primary--text font-weight-medium" v-text="item.title"></v-list-item-title>
                        <v-list-item-subtitle class="text-uppercase accent--text" v-text="item.subtitle"></v-list-item-subtitle>
                        <p class="font-weight-light mb-0 pa-0 caption d-flex text-truncate">Color</p>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card-text>
            </v-card>
          </v-card>
        </template>
      </v-dialog>
    </div>
    <v-dialog transition="dialog-top-transition" fullscreen>
      <template v-slot:activator="{ on, attrs }">
        <!-- <v-badge overlap bordered dot color="secondary"> -->
          <v-btn class="icon ticket" icon large v-bind="attrs" v-on="on"></v-btn>
        <!-- </v-badge> -->
      </template>
      <template v-slot:default="dialog">
        <v-card class="ticket-dialog">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="icon user" icon large v-bind="attrs" v-on="on"> </v-btn>
          </template>
          <v-toolbar class="col-12 pa-0 ma-0 align-center pr-2 elevation-0">
            <!-- <userselect /> -->
            <v-text-field
              class="my-3"
              label="Ciente Asociado"
              v-model="vCliente"
              hide-details="auto"
            ></v-text-field>
            <v-btn icon large color="primary" @click="dialog.value = false"><v-icon>mdi-close</v-icon> </v-btn>
          </v-toolbar>

          <v-card class="elevation-0 padding">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="icon ticket" icon large v-bind="attrs" v-on="on"> </v-btn>
            </template>
            <v-card-text class="pa-0">
              <v-tabs fixed-tabs centered color="primary">
                <v-tab fixed-tabs>
                  Carrito
                </v-tab>
                <v-tab fixed-tabs>
                  Tickets
                </v-tab>
                <v-tab-item>
                  <cart :idCliente="vCliente"/>
                </v-tab-item>
                <v-tab-item>
                  <lista-tickets/>
                </v-tab-item>
              </v-tabs>
              <v-row class="col-12 py-0 px-2 ma-0">
                <v-col class="col-12 ma-0 py-0"> </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card>
      </template>
    </v-dialog>
    <v-dialog transition="dialog-top-transition" max-width="600" fullscreen>
      <template v-slot:activator="{ on, attrs }">
        <!-- <v-badge overlap bordered dot color="secondary"> -->
          <v-btn class="icon user" icon large v-bind="attrs" v-on="on"></v-btn>
        <!-- </v-badge> -->
      </template>
      <template v-slot:default="dialog">
        <v-card class="user-dialog background">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="icon user" icon large v-bind="attrs" v-on="on"> </v-btn>
          </template>
        
          <userselect v-on:cerrar="dialog.value = false" />
         
          <!-- <v-card-text class="padding">
            <div class="text-subtitle text-uppercase py-4">Dar de alta un nuevo cliente</div>
            <form>
              <v-text-field class="user" label="Nombre y apellido" color="accent" prepend-inner-icon=" " v-model="nombreCompleto"></v-text-field>
              <v-text-field class="dni" label="DNI" color="accent" v-model="dni" prepend-inner-icon=" "></v-text-field>
              <v-text-field class="telefono" label="Teléfono" color="accent" v-model="telefono" prepend-inner-icon=" "></v-text-field>
              <v-text-field class="email" label="Email" color="accent" v-model="email" prepend-inner-icon=" "></v-text-field>
            </form>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn class="icon guardar" @click="guardar" fab color="secondary" large elevation="0" bottom absolute right></v-btn>
          </v-card-actions> -->
        </v-card>
      </template>
    </v-dialog>
  </v-row>
</template>

<script lang="ts">
import Vue from 'vue';
import Scan from '../Shop/scan.vue';
import userselect from '../Shop/userselect.vue';
import Cart from './Cart.vue';
import ListaTickets from './ListaTickets.vue';
export default Vue.extend({
  name: 'Header',
  components: {
    Scan,
    userselect,
    Cart,
    ListaTickets,
  },
  data() {
    return {
      absolute: true,
      overlay: false,
      selectedItem: '',
      localizador: '',
      searchList: [],
      vCliente: ''
    };
  },
  computed: {
    historialBusqueda() {
      return this.$store.state.tienda.historialBusqueda;
    },
    cliente() {
      return this.$store.getters.getCliente;
    },
  },
  watch: {
    historialBusqueda(value) {
      this.searchList = value;
    },
    cliente(value) {
      if (value !== null) {
        this.vCliente = value.email;
      } else {
        this.vCliente = '';
      }
    },
  },
  created() {
    if (this.cliente !== null) {
      this.vCliente = this.cliente.email
    }
    this.searchList = this.$store.state.tienda.historialBusqueda;
  },
  methods: {
    searchByLocalizador() {
      if (this.localizador.length > 0) {
        if (this.localizador.length === 13) {
          this.searchByEanCode(this.localizador)
        } else {
          this.$store.commit('setHasUserSearched', this.$store.state.tienda.hasSearched + 1);
          this.$store.commit('setProductLocalizadorSearched', this.localizador.trim());
          this.$store.commit('cambioPag', 1);
          this.$router.push({ name: 'Home' });
        }
      }
    },
    searchByEanCode(ean) {
      if (ean.length > 0) {
        ean = ean.trim()
        this.$store.commit('setHasUserSearched', this.$store.state.tienda.hasSearched + 1);
        this.$store.commit('setProductEanSearched', ean.replace(/[^A-Z0-9]/gi, ''));
        this.$store.commit('cambioPag', 1);
        this.$router.push({ name: 'Home' });
      } else {
        this.$root.$emit('mostrarAlerta', 'No se ha podido escanear el código', 'error')
      }
    },
    reset() {
      this.localizador = '';
      this.$store.commit('cambioPag', 1);
      this.$store.commit('setHasUserSearched', 0);
      this.$store.commit('setProductLocalizadorSearched', '');
      this.$store.commit('setProductEanSearched', '');
    },
  },
});
</script>
