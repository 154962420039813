<template>
 <v-sheet class="menu-prin">
     <v-btn
        fab
        x-large
        @click.stop="drawer = !drawer"
        class="elevation-0 menu-btn"
      >
      <v-icon>mdi-menu</v-icon>
    </v-btn>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      >
      <v-list-item class="d-flex flex-column">
          <v-img
            :src="(!isSys4Conjunto()) ? require('../assets/logo_mobile_store.svg') : require('../assets/logo_mobileStore_sys4.png')"
            contain
            class="align-self-start mt-2"
            height="45"
            width="200"
            />
          <router-link class="flex-grow-0 profile-link" :to="{name: 'Profile', params:{persona: persona}}">
            <v-icon class="icon perfil"></v-icon>
          </router-link>
          <v-list-item-avatar  size="60">
            <v-img :src="foto"></v-img>
          </v-list-item-avatar>
          <v-list-item class="flex-nowrap align-center d-flex w-100">
            <v-list-item-title class="h2 primary--text font-weight-medium text-uppercase d-flex">
              {{ this.nombreCompleto }}
                  <!-- <div class="conection ml-2" :class="trabajando ? 'is-online' : 'is-offline'"></div> -->
            </v-list-item-title>
          </v-list-item>
          <v-autocomplete
            label="Prepend inner"
            :items="shops"
            color="accent"
            prepend-inner-icon="mdi-map-marker"
            :item-text="isSys4() ? 'clicode' : 'nombre'"
            item-value="id"
            return-object
            v-model="idTienda"
            @input="cambioTienda"
          ></v-autocomplete>
      </v-list-item>
      <v-divider></v-divider>
      <v-list class="pa-0 d-flex flex-column"
         flat>
        <v-list-item-group class="pa-0">
          <router-link to="/tienda" v-if="permitido('MOBILESTORE.PHONESERVICES.TIENDA')">
            <v-list-item class="py-1">
              <v-icon class="icon carro"></v-icon>
            Tienda
            </v-list-item>
          </router-link> 
          <router-link to="/calendar" v-if="permitido('MOBILESTORE.PHONESERVICES.CALENDARIO') && !isSys4()"> 
            <v-list-item class="py-1">
            <v-icon class="icon calendario"></v-icon>
            Calendario
            </v-list-item>
          </router-link> 
          <router-link to="/timecontrol" v-if="permitido('MOBILESTORE.PHONESERVICES.CONTROLHORARIO') && !isSys4()">
            <v-list-item class="py-1">
              <v-icon class="icon reloj"></v-icon>
              Control horario
              </v-list-item>
          </router-link>
          <router-link to="/timeline" v-if="permitido('MOBILESTORE.PHONESERVICES.TIMELINE') && !isSys4()">
            <v-list-item class="py-1">
              <v-icon class="icon timeline"></v-icon>
                Timeline
            </v-list-item>
          </router-link>
          <!-- <router-link to="/chat" v-if="permitido('MOBILESTORE.PHONESERVICES.CHAT')">
            <v-list-item class="py-1">
                <v-icon class="icon chat"></v-icon> chat
            </v-list-item>
          </router-link> -->
          <router-link to="/order" v-if="permitido('MOBILESTORE.PHONESERVICES.PEDIDOS') " v-show="false">
            <v-list-item class="py-1">
                <v-icon class="icon caja"></v-icon> Pedidos
            </v-list-item>
          </router-link>
          <router-link to="/bi" v-if="permitido('MOBILESTORE.PHONESERVICES.ESTADISTICAS') && !isSys4()">
            <v-list-item class="py-1">
                <v-icon class="icon datos"></v-icon> Estadisticas
            </v-list-item>
          </router-link>
          <router-link to="/inventario" v-if="permitido('MOBILESTORE.PHONESERVICES.MODSTOCK') && !isSys4()">
            <v-list-item class="py-1">
                <v-icon class="icon scan"></v-icon> Modificar stock
            </v-list-item>
          </router-link>
          <router-link to="/inventariototal" v-if="permitido('MOBILESTORE.PHONESERVICES.INVENTARIO')">
            <v-list-item class="py-1">
                <v-icon class="icon scan"></v-icon> Inventario
            </v-list-item>
          </router-link>
           <router-link to="/Link" v-if="permitido('MOBILESTORE.PHONESERVICES.WEBLINKS') && !isSys4()" v-show="false">
            <v-list-item class="py-1">
                <v-icon class="icon link"></v-icon> Links
            </v-list-item>
          </router-link>
            <router-link to="/Note" v-if="permitido('MOBILESTORE.PHONESERVICES.NOTAS') && !isSys4()" v-show="false">
            <v-list-item class="py-1">
                <v-icon class="icon notas"></v-icon> Notas
            </v-list-item>
          </router-link>
          <router-link to="/Workflow" v-if="permitido('MOBILESTORE.PHONESERVICES.WORKFLOW') && !isSys4()" v-show="false">
            <v-list-item class="py-1">
                <v-icon class="icon workflow"></v-icon> Workflow
            </v-list-item>
          </router-link>
        </v-list-item-group>
   
        <v-list-item-group class="mt-auto"> 
             <v-divider class="mt-auto"></v-divider>
          <router-link class="d-block grey--text text--darken-1" to="/Logout">
            <v-list-item class="py-1">
              <v-icon class="icon off"></v-icon>
              Salir
            </v-list-item>
          </router-link>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </v-sheet>
  

</template>


<script lang="ts">
  import Vue from 'vue'
  export default Vue.extend({
    name: 'Menu',
   
    data: () => ({
      drawer: false,
      group: null,
      idTienda: '',
      nombreCompleto: '',
      foto: '',
      persona: {}
    }),
    computed: {
      trabajando () {
        return this.$store.state.userInfo.trabajando 
      },
      shops () {
        return this.$store.state.userInfo.listaTiendas
      },
    },
    watch: {
      group () {
        this.drawer = false
      },
    },
    created() {
      this.idTienda = this.$store.state.userInfo.idTienda
      this.nombreCompleto = this.$store.state.userInfo.datos.mspersonanombre
      if (this.$store.state.userInfo.datos.mspersonaimagen !='' && this.$store.state.userInfo.datos.mspersonaimagen != null)  {
        this.foto = 'data:image/png;base64, ' + this.$store.state.userInfo.datos.mspersonaimagen
      } else {
        this.foto = require(`@/assets/images/no_photo.jpg`)
      }
    },
    methods: {
      cambioTienda(value){
      this.$store.dispatch("cambioTienda", value)
        .then(() => {
          // this.$cookies.set("idtienda", value)
        })
        .catch(error => {
          console.log(error)
        })
    },
    }
  })
</script>
