<template>
<v-app>

 <v-container fluid>
    <v-row>
        <h2 class="primary--text px-2 py-6 text-center col-12">Análisis tienda</h2>
    </v-row>
    <v-layout class="milayout">
      <v-row style="width: 88%;">
        <v-col  cols="12" sm="12" class="pb-0" v-if="false">
          <v-select
            v-model="vcomboTipoTienda"
            :items="comboTipoTienda"
            item-value="id"
            item-text="name"
            @change="actualizarTabla()"
            class="px-8 pl-8 pt-0 mt-0"
          ></v-select>
        </v-col>
        <v-col  cols="12" sm="12" class="pt-0">
          <v-select
            v-model="vcomboTiempo"
            :items="comboTiempo"
            item-value="id"
            item-text="name"
            value="Hoy"
            @change="actualizarTabla()"
            class="px-8 pl-8 pt-0 mt-0"
          ></v-select>
        </v-col>
        <v-col  cols="12" sm="12" class="pa-0">
          <v-layout>
            <v-data-table
              :headers="headers"
              :items="datosTabla"
              :sort-by="['id.ps0003_tienda']"
              item-key="id.ps0003_tienda"
              hide-default-footer
              :items-per-page= "100000"
              :mobile-breakpoint = "365"
              :loading = cargando
              class="elevation-0 rounded-0 pa-0"
              style="overflow: auto; width: 100%;"
            >
              <template v-slot:item.ps0003_Valor="{ item }">
                <span>{{ formatNum(item.ps0003_Valor) }}€</span>
              </template>
              <template v-slot:item.ps0003_Beneficio="{ item }">
                <span>{{ formatNum(item.ps0003_Beneficio) }}€</span>
              </template>
              <template v-slot:item.ps0003_ROI="{ item }">
                <span>{{ item.ps0003_ROI }}</span>
              </template>
              <template v-slot:body.append="{}" v-if="datosTabla.length">
                <tr class="totales">
                    <td class="text-center">TOTAL</td>
                    <td class="text-right">{{totalUnidades}}</td>
                    <td class="text-right">{{totalValor}}€</td>
                    <td class="text-right">{{totalBeneficio}}€</td>
                    <td class="text-center">{{totalRoi}}</td>
                </tr>
              </template>
              <template v-slot:no-data>
                <v-alert :value="true">
                  No hay datos
                </v-alert>
              </template>
            </v-data-table>
          </v-layout>
        </v-col>
      </v-row>
    </v-layout>
  </v-container>
  </v-app>
</template>
<script>
import { PsApiService } from '@/api/PsApiService'
import { Util } from '@/api/Util'

const psApiService = new PsApiService()
const util = new Util()

export default {
       components: {
    
  },
  data () {
    return {
      vcomboTiempo: 1,
      comboTiempo: [
        {
          id: 1,
          name: 'Ayer'
        },
        {
          id: 2,
          name: 'Hoy'
        },
        {
          id: 3,
          name: 'Semana'
        },
        {
          id: 4,
          name: 'Mes'
        },
        {
          id: 5,
          name: 'Año'
        }
      ],
      datosTabla: [],
      headers: [
        {
          text: 'Tienda',
          align: 'center',
          sortable: true,
          value: 'id.ps0003_tienda',
           class:'white--text'
        },
        { text: 'Unidades', sortable: true, value: 'ps0003_Unidades', align: 'right',  class:'white--text' },
        { text: 'Valor', sortable: true, value: 'ps0003_Valor', align: 'right',  class:'white--text' },
        { text: 'Beneficio', sortable: true, value: 'ps0003_Beneficio', align: 'right',  class:'white--text' },
        { text: '% ROI', sortable: false, value: 'ps0003_ROI', align: 'center',  class:'white--text' }
      ],
      vcomboTipoTienda: '--',
      comboTipoTienda: [],
      cargando: false
    }
  },
  computed: {
    regenerar () {
      return this.$store.getters.isRegenerarTablas
    },
    totalUnidades () {
      if (this.datosTabla.length) {
        let sum
        sum = this.datosTabla.map(item => item.ps0003_Unidades).reduce((prev, next) => prev + next)
        return sum
      } else {
        return 0
      }
    },
    totalValor () {
      if (this.datosTabla.length) {
        let sum
        sum = this.datosTabla.map(item => item.ps0003_Valor).reduce((prev, next) => prev + next)
        sum = sum.toLocaleString(undefined, { minimumFractionDigits: 0 })
        return sum
      } else {
        return 0
      }
    },
    totalBeneficio () {
      if (this.datosTabla.length) {
        let sum
        sum = this.datosTabla.map(item => item.ps0003_Beneficio).reduce((prev, next) => prev + next)
        sum = sum.toLocaleString(undefined, { minimumFractionDigits: 0 })
        return sum
      } else {
        return 0
      }
    },
    totalRoi () {
      if (this.datosTabla.length) {
        let sum, sum2, porcentaje
        sum = this.datosTabla.map(item => item.ps0003_ValorVentaSinIVA).reduce((prev, next) => prev + next)
        sum2 = this.datosTabla.map(item => item.ps0003_Costo).reduce((prev, next) => prev + next)
        porcentaje = Math.round(((sum - sum2) / sum) * 100)
        // porcentaje = ((sum2 / sum) * 100).toFixed(2)
        return porcentaje
      } else {
        return 0
      }
    }
  },
  watch: {
    regenerar (value) {
      console.log(`My store value for 'regenerar' changed to ${value}`)
      this.actualizarTabla()
    }
  },
  methods: {
    formatNum (numero) {
      if (numero !== undefined) {
        return numero.toLocaleString(undefined, { minimumFractionDigits: 0 })
      } else {
        return null
      }
    },
    actualizarTabla () {
      this.cargando = true
      psApiService
        .getAnalisisTienda(this.vcomboTiempo, this.vcomboTipoTienda, this.$store.state.userInfo.uid)
        .then(data => {
          if (data !== "") {
            this.datosTabla = data
          } else {
            this.datosTabla = []
          }
          this.cargando = false
        })
        .catch(error => {
          util.mostrarEnConsola(
            'getAnalisisTienda',
            error,
            'ERROR'
          )
          this.datosTabla = []
          this.cargando = false
        })
    },
    getAllTipoTiendaAnalisis () {
      this.comboTipoTienda = []
      this.comboTipoTienda.push({ id: '--', name: 'Todas' })
      psApiService
        .getAllTipoTiendaAnalisis()
        .then(data => {
          for (var i = 0; i < data.length; i++) {
            this.comboTipoTienda.push(data[i])
          }
          util.mostrarEnConsola(
            'getAllTipoTiendaAnalisis',
            data.length,
            'OK'
          )
          this.vcomboTipoTienda = '--'
          this.actualizarTabla()
        })
        .catch(error => {
          util.mostrarEnConsola('getAllTipoTiendaAnalisis', error, 'ERROR')
          this.vcomboTipoTienda = '--'
          this.actualizarTabla()
        })
    }
  },
  created () {
    this.getAllTipoTiendaAnalisis()
  }
}
</script>
