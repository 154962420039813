var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('h2',{staticClass:"primary--text px-2 py-6 text-center col-12"},[_vm._v("Venta neta")])]),_c('v-row',[(false)?_c('v-col',{staticClass:"py-0 px-8",attrs:{"cols":"12","sm":"12"}},[_c('v-select',{staticClass:"pt-0 mt-0",attrs:{"items":_vm.comboTipoTienda,"item-value":"id","item-text":"name","Label":"Tienda"},on:{"change":function($event){return _vm.actualizarTabla()}},model:{value:(_vm.vcomboTipoTienda),callback:function ($$v) {_vm.vcomboTipoTienda=$$v},expression:"vcomboTipoTienda"}})],1):_vm._e(),_c('v-col',{staticClass:"pl-8",attrs:{"cols":"6","sm":"6"}},[_c('v-select',{staticClass:"pt-0 mt-0",attrs:{"items":_vm.comboValor,"item-value":"id","item-text":"name","label":"Valor"},on:{"change":function($event){return _vm.actualizarTabla()}},model:{value:(_vm.vcomboValor),callback:function ($$v) {_vm.vcomboValor=$$v},expression:"vcomboValor"}})],1),_c('v-col',{staticClass:"pr-8",attrs:{"cols":"6","sm":"6"}},[_c('v-select',{staticClass:"pt-0 mt-0",attrs:{"items":_vm.comboTiempo,"item-value":"id","item-text":"name","value":"Hoy","label":"Fecha"},on:{"change":function($event){return _vm.actualizarTabla()}},model:{value:(_vm.vcomboTiempo),callback:function ($$v) {_vm.vcomboTiempo=$$v},expression:"vcomboTiempo"}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"12"}},[_c('v-data-table',{staticClass:"pa-0 rounded-0",attrs:{"headers":_vm.headers,"items":_vm.datosTabla,"sort-by":['tienda'],"hide-default-footer":"","items-per-page":100000,"mobile-breakpoint":365,"loading":_vm.cargando},scopedSlots:_vm._u([(_vm.vcomboValor === 1)?{key:"item.unidades",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.unidades))])]}}:{key:"item.valor",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatNum(item.valor))+"€")])]}},(_vm.vcomboValor === 1)?{key:"item.unicomp",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.getColor(item)},[_vm._v(_vm._s(item.unicomp))])]}}:{key:"item.valorcomp",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.getColor(item)},[_vm._v(_vm._s(_vm.formatNum(item.valorcomp))+"€")])]}},(_vm.vcomboValor === 1)?{key:"item.porcentajeUni",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.getColor(item)},[_vm._v(_vm._s(item.porcentajeUni)+"%")])]}}:{key:"item.porcentajeValor",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.getColor(item)},[_vm._v(_vm._s(item.porcentajeValor)+"%")])]}},{key:"item.semaforo",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item),"dark":""}},[_vm._v(_vm._s(item.semaforo))])]}},(_vm.datosTabla.length)?{key:"body.append",fn:function(ref){return [_c('tr',{staticClass:"totales"},[_c('td',{staticClass:"text-center"},[_vm._v("TOTAL")]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.totalValor))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.totalComp))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.totalPorc)+"%")]),_c('td',{staticClass:"text-center"},[_c('v-chip',{attrs:{"color":_vm.totalSem,"dark":""}})],1)])]}}:null,{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"value":true}},[_vm._v(" No hay datos ")])]},proxy:true}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }