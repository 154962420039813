import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
// import { set, keys, getAll } from '../plugins/idb.js';
import { createStore } from 'vuex-extensions'

import LoginApiService from '@/api/LoginApiService'

Vue.use(Vuex)

export default createStore(Vuex.Store,{
  state: {
    lang: "es",
    userInfo: {
      name: '',
      user: '',
      isSignedUp: false,
      acl: [],
      iniOps: null,
      opuid: '',
      listaTiendas: [],
      idTienda: '',
      wsid: '',
      uid: '',
      id: '',
      chatAgentId: '',
      trabajando: false,
      minutosTrabajados: 0,
      minutosATrabajar: 480,
      extender10Min: false,
      extenderIlimitado: false,
      diaTrabajo: '',
      sesion: null,
      datos () {
        return {
        }
      }
    },
    tienda: {
      npag: 1,
      totalPag: 0,
      productLocalizadorSearched: '',
      productEanSearched: '',
      hasSearched: 0,
      hasTicketSearched: 0,
      historialBusqueda: [],
      productsCart:[],
      ticketsAparcados: [],
      ticketVendedorSearched:'',
      ticketClienteSearched: '',
      ticketFechaDesdeSearched:'',
      ticketFechaHastaSearched:'',
      ticketTipoSearched:'',
    },
    clienteInfo: {
      datos: null,
      busquedaCliente: '',
      likeList: [],
    },
    inventarioArticulosAvanzado: [],
    inventarioArticulosSimple: [],
    isOffline: false,
  },
  getters: {
    getNpag: state => state.tienda.npag,
    getTotalPag:  state => state.tienda.totalPag,
    getOpuid: state => state.userInfo.opuid,
    getIdTienda: state => state.userInfo.idTienda,
    getProductsCart: state => state.tienda.productsCart,
    getTicketsAparcados: state => state.tienda.ticketsAparcados,
    getCliente: state => state.clienteInfo.datos,
    getBusquedaCliente: state => state.clienteInfo.busquedaCliente,
    getChatAgentId: state => state.userInfo.chatAgentId,
    getAcl: state => state.userInfo.acl,
    getSesion: state => state.userInfo.sesion,
    getIsOffline: state => state.isOffline
  },
  mutations: {
    saveLang(state, lang) {
      state.lang = lang;
    },
    loginSuccess(state, payload) {
      state.userInfo.isSignedUp = true;
      state.userInfo.name = payload.name;
      state.userInfo.user = payload.user;
      state.userInfo.acl = payload.acl;
      state.userInfo.iniOps = payload.iniOps;
      state.userInfo.uid = payload.userUid;
      state.userInfo.id = payload.userId;
    },
    loginError(state, payload) {
        state.userInfo.isSignedUp = false;
        state.userInfo.name = payload.name;
    },
    logout(state) {
      state.userInfo.isSignedUp = false;
      state.userInfo.name = '';
      state.userInfo.acl = null;
      state.userInfo.iniOps = null;
      state.userInfo.uid = '';
      state.userInfo.id = '';
      state.userInfo.sesion = null;
    },
    cambioTienda(state, tienda) {
      state.userInfo.idTienda = tienda.id;
      state.userInfo.opuid = state.userInfo.listaTiendas.find(element => element.id == tienda.id).opuid;
      state.userInfo.wsid = state.userInfo.listaTiendas.find(element => element.id == tienda.id).wsid;
      state.tienda.productsCart = []
    },
    setSesion(state, data) {
      state.userInfo.sesion = data;
    },
    setChatId(state, id) {
      state.userInfo.chatAgentId = id
    },
    setPersona(state, persona) {
      state.userInfo.datos = persona
    },
    setTrabajando(state, trabajando) {
      state.userInfo.trabajando = trabajando
    },
    cambioPag: (state, data)  => {
      state.tienda.npag = data
    },
    setHasUserSearched: (state, hasSearched) => {
      state.tienda.hasSearched = hasSearched;
    },
    setProductLocalizadorSearched: (state, productLocalizadorSearched) => {
      state.tienda.productLocalizadorSearched = productLocalizadorSearched;
    },
    setProductEanSearched: (state, productEanSearched) => {
      state.tienda.productEanSearched = productEanSearched;
    },
    addToHistorialBusqueda: (state, data) => {
      // let pass = false
      // state.tienda.historialBusqueda.forEach(el => {
      //   if (data.id === el.id && data.codigo === el.codigo) {
      //     if (el.cantidad + data.cantidad > data.stock)  {
      //       console.log ("stock excedido")
      //     } else {
      //       el.cantidad = el.cantidad + data.cantidad;
      //     }
      //     pass = true;
      //   }
      // }, pass);
      // if (!pass) {
      state.tienda.historialBusqueda.unshift(Object.assign({}, data));
      if (state.tienda.historialBusqueda.length > 6 ) {
        state.tienda.historialBusqueda.length=6
      }
      // }
    },
    setInventarioArticulosAvanzado: (state, data) => {
      state.inventarioArticulosAvanzado = data
    },
    setInventarioArticulosSimple: (state, data) => {
      state.inventarioArticulosSimple = data
    },
    addToInventarioArticulosAvanzado: (state, data) => {
      state.inventarioArticulosAvanzado.push(data)
    },
    addToInventarioArticulosSimple: (state, data) => {
      state.inventarioArticulosSimple.push(data)
    },
    unshiftToInventarioArticulosSimple: (state, data) => {
      state.inventarioArticulosSimple.unshift(data)
    },
    addUdInventarioArticulosAvanzado: (state, ean) => {
      state.inventarioArticulosAvanzado.find(element => element.ean == ean).unidades += 1;
    },
    removeUdInventarioArticulosAvanzado: (state, ean) => {
      state.inventarioArticulosAvanzado.find(element => element.ean == ean).unidades -= 1;
    },
    removeFromInventarioArticulosAvanzado: (state, data) => {
      state.inventarioArticulosAvanzado.splice(data,1)
    },
    removeFromInventarioArticulosSimple: (state, data) => {
      state.inventarioArticulosSimple.splice(data,1)
    },
    editFromInventarioArticulosSimple: (state, data) => {
      state.inventarioArticulosSimple.find(element => element.msiId == data.msiId).msiCantidad = data.msiCantidad;
    },
    setIsOffline: (state,data) => {
      state.isOffline = data
    },
    addToCart: (state, data) => {
      let pass = false
       state.tienda.productsCart.forEach(el => {
        if (data.id === el.id && data.codigo === el.codigo) {
          if (el.cantidad + data.cantidad > data.stock)  {
            console.log ("stock excedido")
          } else {
            el.cantidad = el.cantidad + data.cantidad;
          }
          pass = true;
        }
      }, pass);
      if (!pass) {
       state.tienda.productsCart.push(Object.assign({}, data));
      }
    },
    removeFromCart: (state, data) => {
      state.tienda.productsCart.forEach(function (item, index) {
        if (data.id === item.id && data.codigo === item.codigo) {
          state.tienda.productsCart.splice(index,1);
        }
      })
    },
    removeFromTicketsAparcados: (state, id) => {
      state.tienda.ticketsAparcados.forEach(function (item, index) {
        if (id === item.cabecera.msct_uid) {
          state.tienda.ticketsAparcados.splice(index,1);
        }
      });
    },
    setCliente: (state, cliente) => {
      state.clienteInfo.datos = cliente
    },
    setBusquedaCliente: (state, busqueda) => {
      state.clienteInfo.busquedaCliente = busqueda
    },

  },
  actions: {
    saveLang(context, lang) {
      context.commit("saveLang", lang);
      sessionStorage.setItem('lang', lang);
    },
    setCliente({commit}, cliente){
      commit("setCliente", cliente);
    },
    setBusquedaCliente({commit}, busqueda){
      commit("setBusquedaCliente", busqueda);
    },
    cambioTienda({commit}, id){
      commit("cambioTienda", id);
    },
    login({commit}, {user, password, instalacion}) {
      return new Promise((resolve, reject) => {
        LoginApiService.postLogin(user, password, instalacion)
          .then(response => {
            if(response.status === 200) {
              this.state.userInfo.listaTiendas = []
              if (response.data.ops) {
                //console.log(response.data.acl)
                commit('loginSuccess', {
                  name: response.data.user.substring(0,50),
                  user: user,
                  acl: response.data.acl,
                  userUid: response.data.uid,
                  iniOps: response.data.iniOps,
                  userId: response.data.idReprese,
                });
                localStorage.setItem('tokenOauth',response.data.tokenOauth);
                localStorage.setItem('token',response.data.token);
                localStorage.setItem('user', user)
                localStorage.setItem('instalacion', response.data.instalacion)
                let listaTiendasAux = []
                response.data.ops.forEach(el => {
                  let opAux = {
                    id: el.msopid,
                    nombre: el.msopnombre,
                    opuid: el.msopuid,
                    wsid: el.msopparentworkspace,
                    clicode: el.msopclicode
                  }
                  listaTiendasAux.push(opAux)
                }, listaTiendasAux);
                this.state.userInfo.listaTiendas = listaTiendasAux
                commit('cambioTienda', this.state.userInfo.listaTiendas[0]);
              } else {
                commit('loginError', {
                  name: user
                });
                reject("No hay tiendas asociadas")
              }
                
            }
            resolve(response)
          })
          .catch(error => {
              console.log("Error: " + error);
              commit('loginError', {
                  name: user
              });
              switch (error.response.status) {
                case 403 :
                  reject("Usuario o contraseña incorrecto")
                  break
                case 400 :
                  reject("Ha ocurrido un error")
                  break
                default :
                  reject("Ha ocurrido un error")
              }
              
          })
          // commit('loginSuccess', {
          //   name: user,
          //   acl: password,
          //   userUid: 'aaa',
          //   iniOps: [],
          //   userId: 'adsdasd'
          // });
          // localStorage.setItem('tokenOauth','adsdads');
          // localStorage.setItem('token','adsasdasd');
          // localStorage.setItem('user', 'asdasd')
          // console.log(reject)
          // resolve(user)
      })
    },
    cambiarPass ({ commit }, { user, password, passNueva }) {
      return new Promise((resolve, reject) => {
        LoginApiService.cambiarPass(user, password, passNueva)
          .then(response => {
            // console.log("Response: '" + response.data + "' with Statuscode " + response.status)
            resolve(response)
          })
          .catch(error => {
            console.log('Error: ' + error)
            if (error.response.status === 403 || error.response.status === 401) {
              commit('login_error', {
                userName: user
              })
            }
            reject(error)
          })
      })
    }
  },
  modules: {
  },
  plugins: [
    createPersistedState()
  ]
})
