import axios from 'axios';

const URL_API_CLIENTE = process.env.VUE_APP_URL_API_CLIENTE;

export class ClienteApiService {
  getAllClientes(npag) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = `${URL_API_CLIENTE}getAllClientes/${npag}/`;
    return axios
      .get(url,config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response.status===401 || error.response.status===403){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }

  getClientesFilter(busqueda,npag){
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = `${URL_API_CLIENTE}getClientesFilter/${busqueda}/${npag}`;
    return axios
      .get(url,config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response.status===401 || error.response.status===403){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }
  getClienteById(clienteId) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = `${URL_API_CLIENTE}getClienteById/${clienteId}`;
    return axios
      .get(url,config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response.status===401 || error.response.status===403){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }
  /**
   * Devolvemos los articulos de una wishlist concreta de un cliente 
   * @param {WishList} wishlist 
   * @returns 
   */
  getArticulosByWishList(wishlist) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = `${URL_API_CLIENTE}getArticulosByWishList`;
    return axios
      .post(url,wishlist,config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response.status===401 || error.response.status===403){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }
  /**
   * Devolvemos todas las wishlists de un cliente
   * @param {*} clienteId 
   * @returns 
   */
  getAllWishLists(clienteId) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = `${URL_API_CLIENTE}getAllWishLists/${clienteId}/${localStorage.getItem("tokenOauth")}`;
    return axios
      .get(url,config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response.status===401 || error.response.status===403){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }
  /**
   * 
   * @param {*} clienteId 
   * @returns 
   */
  getLikesArticulos(clienteId) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = `${URL_API_CLIENTE}getLikesArticulos/${clienteId}/${localStorage.getItem("tokenOauth")}`;
    return axios
      .get(url,config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response.status===401 || error.response.status===403){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }
  /**
   * 
   * @param {*} clienteId 
   * @returns 
   */
  getHistorialArticulos(clienteId) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = `${URL_API_CLIENTE}getHistorialArticulos/${clienteId}/${localStorage.getItem("tokenOauth")}`;
    return axios
      .get(url,config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response.status===401 || error.response.status===403){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }
   /**
   * 
   * @param {*} clienteUid 
   * @returns 
   */
  getAllTickets(clienteUid) {
    // clienteUid = 'D1D6A779-5351-41C0-B8AB-8043F69FA42A'
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = `${URL_API_CLIENTE}getAllTickets/${clienteUid}`;
    return axios
      .get(url,config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response.status===401 || error.response.status===403){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }
  anadirCliente(cliente) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = `${URL_API_CLIENTE}anadirCliente`;
    return axios
      .post(url,cliente,config)
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        if (error.response.status===401 || error.response.status===403){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }
  /**
   * 
   * @param {*} clienteUid 
   * @param {*} fecha 
   * @returns 
   */
  // getTicketsByFechaIni(clienteUid, fecha) {
  //   clienteUid = 'D1D6A779-5351-41C0-B8AB-8043F69FA42A'
  //   fecha = '20200505'
  //   let config = {
  //     headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
  //   };
  //   const url = `${URL_WS_TICKETS}&CLIENTE_UID=${clienteUid}&FECHA=${fecha}`;
  //   return axios
  //     .get(url,config)
  //     .then(function(response) {
  //       return response.data;
  //     })
  //     .catch(function(error) {
  //       if (error.response.status===401 || error.response.status===403){
  //         window.location.href = process.env.BASE_URL + '#/logout'
  //       }
  //       throw error;
  //     });
  // }
} 