import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './scss/main.scss';
import './assets/css/bi.css';
import vuetify from './plugins/vuetify'
import Global from "./plugins/global.js";

// Usaremos Node para detectar la concurrencia de usuarios

import VueSocketIO from 'vue-socket.io';
import SocketIO from 'socket.io-client'

Vue.config.productionTip = false
Vue.use(Global);

Vue.use( 
  new VueSocketIO({
      debug: true,
      connection: SocketIO(process.env.NODE_ENV === 'development' || window.location.origin.includes('localhost') ? 'http://localhost:3650' : 'wss://servicios.yet.es', 
      {
        path: process.env.NODE_ENV === 'development' || window.location.origin.includes('localhost') ? '' : ''
      }
      ),
      extraHeaders: {
        'Access-Control-Allow-Credentials': true
      },
      allowEIO3: true
      
  })
)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
