<template>
  <div class="text-center">
    <v-pagination
      v-model="page"
      :length="totalPag"
      total-visible="6"
      @input="cambioPagina"
    ></v-pagination>
  </div>
</template>
<script>

export default {
  data() {
    return {
      page: this.$store.getters.getNpag
    }
  },
  computed: {
    totalPag() {
      return this.$store.getters.getTotalPag
    },
    cambioPag () {
      return this.$store.getters.getNpag;
    }
  },
  watch: {
    cambioPag (value) {
      if (this.page !== value) {
        this.page = value
      }
    },
  },
  mounted() {

  },
  methods: {
    cambioPagina () {
      this.$store.commit('cambioPag', this.page)
    }
  }
}
</script>