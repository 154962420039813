<template>
  <div>
    <v-img v-bind:src="foto" class="white--text align-end" max-height="80" max-width="60" contain> </v-img>
    <v-list-item-content class="pl-3">
      <v-list-item-title class="text-uppercase primary--text font-weight-medium">{{ producto.modelo }} {{ producto.color }}</v-list-item-title>
      <v-list-item-subtitle class="text-uppercase accent--text">{{ lineaticket.mslt_codigo_escaneo }}</v-list-item-subtitle>
      <div class="d-flex align-center justify-space-between">
        <!-- <p class="text-truncate pt-3 font-weight-light mb-0 pa-0 caption d-flex text-truncate">Color</p> -->
        <!-- <p class="mb-0">37</p> -->
        <p class="mb-0">{{ lineaticket.mslt_unidades }}</p>
        <p class="font-weight-bold mb-0 text-h6 primary--text">{{ formatMoney(lineaticket.mslt_precio) }}</p>
      </div>
    </v-list-item-content>
  </div>
</template>
<script>
import { TiendaApiService } from '@/api/TiendaApiService';
import { Util } from '@/api/Util';
const tiendaApiService = new TiendaApiService();
const util = new Util();

export default {
  name: 'lineaTicket',
  props: ['lineaticket'],

  data() {
    return {
      producto: {
        modelo: '',
        color: '',
      },
      foto: '',
      talla: '',
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getArticuloByCod();
    },
    formatMoney(num) {
      return util.formatMoney(num);
    },
    getArticuloByCod() {
      tiendaApiService
        .getArticuloByCod(this.lineaticket.mslt_codigo_escaneo)
        .then((data) => {
          if (data.articulo) {
            this.producto = data.articulo;
            this.talla = data.talla;
            this.getFoto();
          } else {
            this.producto.modelo = 'Articulo no encontrado';
            this.producto.color = '';
            this.foto = '';
          }
        })
        .catch((error) => {
          //alert ("Ha ocurrido un error")
          this.producto.modelo = 'Articulo no encontrado';
          this.producto.color = '';
          this.foto = '';
          util.mostrarEnConsola('tiendaApiService - getArticuloByCod', error, 'ERROR');
        });
    },
    getFoto() {
      if (this.producto.fotos.length > 0) {
        this.foto = this.producto.fotos[0].url;
      }
    },
    addToCart() {
      //console.log(this.producto)
      if (this.producto) {
        // this.error = false
        Object.assign(this.producto, { cantidad: this.lineaticket.mslt_unidades });
        Object.assign(this.producto, { codigo: this.lineaticket.mslt_codigo_escaneo });
        Object.assign(this.producto, { precio: this.lineaticket.mslt_precio });
        if (this.talla !== '') {
          Object.assign(this.producto, { talla: this.talla });
        } else {
          let eanAux;
          for (var i = 0; i < this.producto.tallas.length; i++) {
            eanAux = this.producto.tallas[i].eans.find((ean) => ean.ean === this.lineaticket.mslt_codigo_escaneo);
          }
          if (eanAux) {
            Object.assign(this.producto, { talla: this.eanAux.ean });
          }
        }
        Object.assign(this.producto, { foto: this.foto });
        this.$store.commit('addToCart', this.producto);
        return true;
        //this.$emit('cerrar')
      } else {
        console.log('error desaparcar' + this.producto);
        return true;
        // this.error = true
      }
    },
  },
};
</script>
