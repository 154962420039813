<template>
 
  <v-bottom-sheet
      v-model="sheet"
      persistent
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary d-none"
          v-bind="attrs"
          v-on="on"
          absolute
        >
          activar alerta 
        </v-btn>
      </template>
      <v-sheet
        class="py-3"
      >
        <div class="py-3 px-4">
          <v-list-item-title class="mb-1 d-flex align-baseline text-uppercase primary--text font-weight-medium">
           <v-icon class="primary--text col-auto py-0">
            mdi-alert
          </v-icon>Alerta de control horario
        </v-list-item-title>
        <div class="text--primary px-3" v-if="!extender10Min">
          Han pasado 10 minutos de tu horario laboral.
          </div>
          <div class="text--primary px-3" v-if="extender10Min && !extenderIlimitado">
          Han pasado 30 minutos de tu horario laboral.
          </div>
           <div class="text--primary pb-4 px-3">
           ¿Que desea hacer?</div>


        <!--Action buttons para los 10 minutos-->
         <v-card-actions class="d-flex flex-column" v-if="!extender10Min">
        <v-btn
          outlined
          rounded
          text
          color="primary"
          class="col-12 py-2"
          @click="aplazar10min()"
        >
          Aplazar 10 minutos
        </v-btn>
      <v-btn
          text
          color="secondary"
          rounded
          outlined
          @click="terminarJornada()"
          class="col-12 ma-0 mt-3 py-2"
        >
          Terminar jordana
      </v-btn>
    </v-card-actions>
    
    
    <!--Action buttons para los 30 minutos-->
     <v-card-actions class="flex-column" v-if="extender10Min && !extenderIlimitado">
          <v-btn
          outlined
          rounded
          text
          color="primary"
          class="col-12 py-2"
          @click="aplazarSiempre()"
        >
          Seguir tiempo ilimitado
        </v-btn>
      <v-btn
          text
          color="secondary"
          rounded
          outlined
          @click="terminarJornada()"
          class="col-12 ma-0 mt-3 py-2"
        >
          Terminar jornada
      </v-btn>
        </v-card-actions>
        </div>
      </v-sheet>
    </v-bottom-sheet> 

</template>


<script lang="ts">
  import Vue from 'vue'
  import { FichajeApiService } from '../api/FichajeApiService'
  const fichajeApiService = new FichajeApiService()
  const moment = require("moment") 
  export default Vue.extend({
    name: 'Alert',
   
    data: () => ({
     sheet: true,
     items: ['Seguir trabajando sin límite', 'Aplazar 30 minutos', 'Terminar jornada',],
     extender10Min: false,
     extenderIlimitado: false
    }),
    computed: {
      horaActual: function() {
        return moment(this.ahora).format("H:mm")
      },
      fechaActual: function() {
        return moment(this.ahora).format("YYYY-MM-DD");
      },
    },
    created() {
      this.extender10Min = this.$store.state.userInfo.extender10Min 
      this.extenderIlimitado = this.$store.state.userInfo.extenderIlimitado
    },
    methods: {
      aplazar10min() {
        this.$store.state.userInfo.extender10Min = true
        this.extender10Min = true
        this.sheet = !this.sheet
      },
      aplazarSiempre() {
        this.$store.state.userInfo.extenderIlimitado = true
        this.extenderIlimitado = true
        this.sheet = !this.sheet
        clearInterval(this.intervalMinutosTrabajados)
      },
      terminarJornada() {
        clearInterval(this.interval3)
        clearInterval(this.interval)
        clearInterval(this.intervalMinutosTrabajados)
        let fichaje = {
          msfichajefecha: moment(this.fechaActual, "YYYY-MM-DD"),
          msfichajepersonauid: this.$store.state.userInfo.datos.mspersonauid,
          msfichajetipo: 2,
          msfichajetipostr: "S",
          msfichajehora: moment(this.horaActual, "H:mm"),
          msfichajeobs: "Fin de jornada"
        }
        this.createFichaje(fichaje)
        this.$store.state.userInfo.trabajando = false
        this.sheet = !this.sheet
      },
      createFichaje(fichaje) {
        fichajeApiService
          .createFichaje(fichaje)
          .then(() => {
          })
          .catch(error => {
            console.log("Alert jornada - salida " + error)
          })
      },
    },
    

  })
</script>
