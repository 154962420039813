<template>
<div>
 <v-container fluid>
    <v-row>
        <h2 class="primary--text px-2 py-6 text-center col-12">Analisis sección</h2>
    </v-row>
    <v-layout class="milayout">
      <v-row style="width: 88%;">
        <v-col  cols="12" sm="12">
          <v-select
            v-model="vcomboTiempo"
            :items="comboTiempo"
            item-value="id"
            item-text="name"
            value="Hoy"
            class="px-8 pt-0"
            @change="actualizarTabla()"
          ></v-select>
        </v-col>
        <v-col  cols="12" sm="12" class="pa-0">
          <v-layout>
            <v-data-table
              :headers="headers"
              :items="datosTabla"
              :sort-by="['id.ps0004_seccion']"
              item-key="id.ps0004_seccion"
              hide-default-footer
              :items-per-page= "100000"
              :mobile-breakpoint = "365"
              :loading = cargando
             class="rounded-0"
              style="overflow: auto; width: 100%;"
            >
              <template v-slot:item.ps0004_Valor="{ item }">
                <span>{{ formatNum(item.ps0004_Valor) }}€</span>
              </template>
              <template v-slot:item.ps0004_Beneficio="{ item }">
                <span>{{ formatNum(item.ps0004_Beneficio) }}€</span>
              </template>
              <template v-slot:item.ps0004_ROI="{ item }">
                <span>{{ formatNum(item.ps0004_ROI) }}</span>
              </template>
              <template v-slot:body.append="{}" v-if="datosTabla.length">
                <tr class="totales">
                    <td class="text-left">TOTAL</td>
                    <td class="text-right">{{totalUnidades}}</td>
                    <td class="text-right">{{totalValor}}€</td>
                    <td class="text-right">{{totalBeneficio}}€</td>
                    <td class="text-center">{{totalRoi}}</td>
                </tr>
              </template>
              <template v-slot:no-data>
                <v-alert :value="true">
                  No hay datos
                </v-alert>
              </template>
            </v-data-table>
          </v-layout>
        </v-col>
      </v-row>
    </v-layout>
  </v-container>
  </div>
</template>
<script>
import { PsApiService } from '@/api/PsApiService'
import { Util } from '@/api/Util'

const psApiService = new PsApiService()
const util = new Util()

export default {
     components: {
  },
  data () {
    return {
      vcomboTiempo: 1,
      comboTiempo: [
        {
          id: 1,
          name: 'Ayer'
        },
        {
          id: 2,
          name: 'Hoy'
        },
        {
          id: 3,
          name: 'Semana'
        },
        {
          id: 4,
          name: 'Mes'
        },
        {
          id: 5,
          name: 'Año'
        }
      ],
      datosTabla: [],
      headers: [
        {
          text: 'Seccion',
          align: 'left',
          sortable: true,
          value: 'id.ps0004_seccion',
          width: '20%',
           class:'white--text'
        },
        { text: 'Unidades', sortable: true, value: 'ps0004_Unidades', align: 'right', width: '20%',  class:'white--text' },
        { text: 'Valor', sortable: true, value: 'ps0004_Valor', align: 'right', width: '20%', class:'white--text' },
        { text: 'Beneficio', sortable: true, value: 'ps0004_Beneficio', align: 'right', width: '20%', class:'white--text' },
        { text: '% ROI', sortable: false, value: 'ps0004_ROI', align: 'center', width: '20%', class:'white--text' }
      ]
    }
  },
  computed: {
    regenerar () {
      return this.$store.getters.isRegenerarTablas
    },
    totalUnidades () {
      if (this.datosTabla.length) {
        let sum
        sum = this.datosTabla.map(item => item.ps0004_Unidades).reduce((prev, next) => prev + next)
        return sum
      } else {
        return 0
      }
    },
    totalValor () {
      if (this.datosTabla.length) {
        let sum
        sum = this.datosTabla.map(item => item.ps0004_Valor).reduce((prev, next) => prev + next)
        sum = sum.toLocaleString(undefined, { minimumFractionDigits: 0 })
        return sum
      } else {
        return 0
      }
    },
    totalBeneficio () {
      if (this.datosTabla.length) {
        let sum
        sum = this.datosTabla.map(item => item.ps0004_Beneficio).reduce((prev, next) => prev + next)
        sum = sum.toLocaleString(undefined, { minimumFractionDigits: 0 })
        return sum
      } else {
        return 0
      }
    },
    totalRoi () {
      if (this.datosTabla.length) {
        let sum, sum2, porcentaje
        sum = this.datosTabla.map(item => item.ps0004_ValorVentaSinIVA).reduce((prev, next) => prev + next)
        sum2 = this.datosTabla.map(item => item.ps0004_Costo).reduce((prev, next) => prev + next)
        porcentaje = Math.round(((sum - sum2) / sum) * 100)
        // porcentaje = ((sum2 / sum) * 100).toFixed(2)
        return porcentaje
      } else {
        return 0
      }
    }
  },
  watch: {
    regenerar (value) {
      console.log(`My store value for 'regenerar' changed to ${value}`)
      this.actualizarTabla()
    }
  },
  methods: {
    formatNum (numero) {
      if (numero !== undefined) {
        return numero.toLocaleString(undefined, { minimumFractionDigits: 0 })
      } else {
        return null
      }
    },
    actualizarTabla () {
      this.cargando = true
      psApiService
        .getAnalisisSeccion(this.vcomboTiempo, this.$store.state.userInfo.uid)
        .then(data => {
          if (data !== "") {
            this.datosTabla = data
          } else {
            this.datosTabla = []
          }
          this.cargando = false
        })
        .catch(error => {
          util.mostrarEnConsola(
            'getAnalisisSeccion',
            error,
            'ERROR'
          )
          this.datosTabla = []
          this.cargando = false
        })
    }
  },
  created () {
    this.actualizarTabla()
  }
}
</script>
