import axios from "axios";
const URL_API_LOGIN = process.env.VUE_APP_URL_API_LOGIN;

export default {
  postLogin(user, password, instalacion) {
    const url = URL_API_LOGIN;
    let param = new URLSearchParams()
    param.append('user', user)
    param.append('password', password)
    param.append('instalacion', instalacion)
    return axios({
          method: 'post',
          url: url,
          data: param
      })
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        throw error;
      });
  },
  postLoginStaff(user, password, op) {
    const url = URL_API_LOGIN + 'loginStaff';
    let param = new URLSearchParams()
    param.append('user', user)
    param.append('password', password)
    param.append('op', op)
    return axios({
          method: 'post',
          url: url,
          data: param
      })
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        throw error;
      });
  },
  cambiarPass (user, password, passNueva) {
    const url = `${URL_API_LOGIN}cambiarPass`
    let param = new URLSearchParams()
    param.append('user', user)
    param.append('password', password)
    param.append('passNueva', passNueva)
    return axios({
      method: 'post',
      url: url,
      data: param
    })
      .then(function (response) {
        return response
      })
      .catch(function (error) {
        throw error
      })
  }
}
