import axios from 'axios'
const URL_API_HORARIODIA = process.env.VUE_APP_URL_API_HORARIODIA

export class HorariodiaApiService {
  /**
   * Devuelve todas las jornadas
   * @return Pais[]
   */
  getAllHorariodia () {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = URL_API_HORARIODIA + 'getAllHorariodia'
    return axios
      .get(url, config)
      .then(function (response) {
        return response.data
      })
      .catch(function(error) {
        if (error.response.status===401 || error.response.status===403){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }

  getHorariodiaById (mshorariodiaid) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = URL_API_HORARIODIA + 'getHorariodiaById/' + mshorariodiaid
    return axios
      .get(url, config)
      .then(function (response) {
        return response.data
      })
      .catch(function(error) {
        if (error.response.status===401 || error.response.status===403){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }

  getHorariodiaByJornadaId (mshoradiajornadaid) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = URL_API_HORARIODIA + 'getHorariodiaByJornadaId/' + mshoradiajornadaid
    return axios
      .get(url, config)
      .then(function (response) {
        return response.data
      })
      .catch(function(error) {
        if (error.response.status===401 || error.response.status===403){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }

  findAllHorariodia (filterHorariodia) {
    let config = {
      headers: {'Authorization': localStorage.getItem("token"), 'instalacion' : localStorage.getItem("instalacion")}
    };
    const url = `${URL_API_HORARIODIA}findAll`
    return axios
      .post(url, filterHorariodia, config)
      .then(function (response) {
        return response.data
      })
      .catch(function(error) {
        if (error.response.status===401 || error.response.status===403){
          window.location.href = process.env.BASE_URL + '#/logout'
        }
        throw error;
      });
  }
}
