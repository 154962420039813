import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#336699',
            secondary: '#F8812D',
            accent: '#6fa7e0',
            error: '#b71c1c',
          },
        },
    },
});
