import axios from "axios";
const URL_API_CHAT = process.env.VUE_APP_URL_API_CHAT;

export class ChatApiService {
  postLogin(nombre) {
    const url = URL_API_CHAT;
    let formData = new FormData()
    formData.append('function', 'login-oauth')
    formData.append('nombre', nombre)
    formData.append('token', localStorage.getItem("tokenOauth"))
    return axios({
          method: 'post',
          url: url,
          data: formData
      })
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        throw error;
      });
  }
  getConversations() {
    const url = URL_API_CHAT;
    let formData = new FormData()
    formData.append('function', 'get-conversations')
    formData.append('login-cookie', localStorage.getItem('tokenChat'))
    formData.append('language', 'false')
    formData.append('routing', 'false')
    return axios({
          method: 'post',
          url: url,
          data: formData
      })
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        throw error;
      });
  }
  getAgentConversations(agent_id) {
    const url = URL_API_CHAT;
    let formData = new FormData()
    formData.append('function', 'get-agent-conversations')
    formData.append('agent_id', agent_id)
    formData.append('login-cookie', localStorage.getItem('tokenChat'))
    return axios({
          method: 'post',
          url: url,
          data: formData
      })
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        throw error;
      });
  }
  getAgentConversation(conversation_id, user_id) {
    const url = URL_API_CHAT;
    let formData = new FormData()
    formData.append('function', 'get-agent-conversation')
    formData.append('login-cookie', localStorage.getItem('tokenChat'))
    formData.append('conversation_id', conversation_id)
    formData.append('user_id', user_id)
    formData.append('language', 'false')
    formData.append('routing', 'false')
    return axios({
          method: 'post',
          url: url,
          data: formData
      })
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        throw error;
      });
  }
  getNewConversations() {
    let d = new Date();
    d.setHours(d.getHours() - 3);
    const url = URL_API_CHAT;
    let formData = new FormData()
    formData.append('function', 'get-new-conversations')
    formData.append('login-cookie', localStorage.getItem('tokenChat'))
    formData.append('datetime', d.toISOString().replace(/T/, ' ').replace(/\..+/, ''))
    formData.append('language', 'false')
    formData.append('routing', 'false')
    formData.append('user_id', 88)
    return axios({
          method: 'post',
          url: url,
          data: formData
      })
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        throw error;
      });
  }
  getAgentInConversations(conversation_id) {
    const url = URL_API_CHAT;
    let formData = new FormData()
    formData.append('function', 'get-agents-in-conversation')
    formData.append('login-cookie', localStorage.getItem('tokenChat'))
    formData.append('language', 'false')
    formData.append('conversation_id', conversation_id)
    return axios({
          method: 'post',
          url: url,
          data: formData
      })
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        throw error;
      });
  }
  sendMessage(mensaje, conversation_id, agent_id, user_id) {
    let d = new Date();
    d.setHours(d.getHours() - 3);
    const url = URL_API_CHAT;
    let formData = new FormData()
    formData.append('function', 'send-message')
    formData.append('login-cookie', localStorage.getItem('tokenChat'))
    formData.append('datetime', d.toISOString().replace(/T/, ' ').replace(/\..+/, ''))
    formData.append('language', 'false')
    formData.append('queue', 'false')
    formData.append('payload', 'false')
    formData.append('conversation_status_code', 'false')
    formData.append('user_id', agent_id)
    formData.append('conversation_id', conversation_id)
    formData.append('message', mensaje)
    formData.append('recipient_id', user_id)
    return axios({
          method: 'post',
          url: url,
          data: formData
      })
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        throw error;
      });
  }
  getNewMessages(conversation_id, user_id) {
    let d = new Date();
    d.setHours(d.getHours() - 3);
    const url = URL_API_CHAT;
    let formData = new FormData()
    formData.append('function', 'get-new-messages')
    formData.append('login-cookie', localStorage.getItem('tokenChat'))
    formData.append('datetime', d.toISOString().replace(/T/, ' ').replace(/\..+/, ''))
    formData.append('conversation_id', conversation_id)
    formData.append('user_id', user_id)
    formData.append('language', 'false')
    formData.append('routing', 'false')
    // formData.append('user_id', 88)
    return axios({
          method: 'post',
          url: url,
          data: formData
      })
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        throw error;
      });
  }
  getAgents() {
    const url = URL_API_CHAT;
    let formData = new FormData()
    formData.append('function', 'get-users')
    formData.append('login-cookie', localStorage.getItem('tokenChat'))
    formData.append('user_types[]', ['agent'])
    formData.append('user_types[]', ['admin'])
    formData.append('language', 'false')
    return axios({
          method: 'post',
          url: url,
          data: formData
      })
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        throw error;
      });
  }
  isAgentOnline(user_id) {
    const url = URL_API_CHAT;
    let formData = new FormData()
    formData.append('function', 'is-online')
    formData.append('login-cookie', localStorage.getItem('tokenChat'))
    formData.append('user_id', user_id)
    return axios({
          method: 'post',
          url: url,
          data: formData
      })
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        throw error;
      });
  }
  getAgentsOnline(agent_id) {
    const url = URL_API_CHAT;
    let formData = new FormData()
    formData.append('function', 'get-online-users')
    formData.append('login-cookie', localStorage.getItem('tokenChat'))
    formData.append('agents', true)
    formData.append('exclude_id', agent_id)
    formData.append('sorting', 'creation_time')
    return axios({
          method: 'post',
          url: url,
          data: formData
      })
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        throw error;
      });
  }
  updateUserLastActivity(agent_id) {
    const url = URL_API_CHAT;
    let formData = new FormData()
    formData.append('function', 'update-users-last-activity')
    formData.append('login-cookie', localStorage.getItem('tokenChat'))
    formData.append('check_slack', true)
    formData.append('language', true)
    formData.append('user_id', agent_id)
    formData.append('return_user_id', agent_id)
    return axios({
          method: 'post',
          url: url,
          data: formData
      })
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        throw error;
      });
  }
  newAgentsCovenrsation(agent_id1, agent_id2) {
    const url = URL_API_CHAT;
    let formData = new FormData()
    formData.append('function', 'new-agents-conversation')
    formData.append('login-cookie', localStorage.getItem('tokenChat'))
    formData.append('agent_id1', agent_id1)
    formData.append('agent_id2', agent_id2)
    return axios({
          method: 'post',
          url: url,
          data: formData
      })
      .then(function(response) {
        return response.data;
      })
      .catch(function(error) {
        throw error;
      });
  }
  
}